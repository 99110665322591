// links

/*
TODO: declare fewer styles on <a> tags altogether
it'll require moving some cheese around
*/
a,
.o-link {
  color: $d3-purple;
  text-decoration: none;

  &:hover {
    color: $d3-purple; // skeleton override
    text-decoration: underline;
  }
}

.o-link.o-link--light {
  // for use with dark backgrounds
  color: $l2-purple;

  &:hover {
    color: $light-white;
  }
}

.o-link.o-link--danger {
  border: unset; // `input` override
  color: $error-color;
}

.o-link__icon {
  font-size: 14px;
  margin-right: 3px;
}

.is-link-icon {
  font-size: $s2-font-size;
  margin-right: 5px;
}

.is-plain-link {
  color: $d2-dark-gray;

  &:hover {
    color: $d2-dark-gray;
    text-decoration: none;
  }
}

.o-link {
  cursor: pointer;
}
