// CHECKBOXES

.o-checkbox {
  border-bottom: 1px solid $l1-light-gray;
  padding: 15px 5px;

  &.o-checkbox:last-of-type {
    margin-bottom: 10px;
  }

  &.o-checkbox--compact {
    border: 0;
    padding: 5px;
  }

  .o-checkbox__input {
    opacity: 0;
    position: absolute; // take it out of document flow
  }

  .o-checkbox__input + label {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 0;
    position: relative;
    white-space: nowrap;
  }

  .o-checkbox__input + label::before {
    background: $light-white;
    border: 1px solid $d3-light-gray;
    border-radius: 3px;
    content: '';
    display: inline-block;
    height: 22px;
    margin-right: 10px;
    vertical-align: text-top;
    width: 22px;
  }

  // checkboxes on hover
  .o-checkbox__input:hover + label::before {
    background: $d1-purple;
    border: 1px solid $d1-purple;
  }

  // checkboxes on focus
  .o-checkbox__input:focus + label::before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  // checked checkboxes
  .o-checkbox__input:checked + label::before {
    background: $d1-purple;
    border: 1px solid $d1-purple;
  }

  // disabled state
  .o-checkbox__input:disabled + label {
    color: $base-mid-gray;
    cursor: auto;
  }

  .o-checkbox__input:disabled + label::before {
    background: $light-white;
    border: 1px solid $d2-light-gray;
    box-shadow: none;
  }

  // the checkmark itself
  .o-checkbox__input:checked + label::after {
    background: $light-white;
    color: $light-white;
    content: '\f00c';
    font: var(--fa-font-solid);
    left: 3px;
    position: absolute;
    top: 10px;
    width: 0;
  }
}
