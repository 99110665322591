
          @use '@scss/all' as wallaby;
        
.description {
  color: var(--mantine-color-gray-6);
}

.label {
  font-size: 16px;
}
