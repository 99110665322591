
          @use '@scss/all' as wallaby;
        
.scriptStatus_card {
  align-items: center;
  border: 1px solid wallaby.$theme-gray-3;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

.scriptStatus_root {
  width: 100%;
}

.scriptStatus_body {
  display: flex;
  justify-content: flex-start;
  position: relative;
}

// extremely hacky way to center radio button
// no flex solutions are working :(
.scriptStatus_inner {
  margin-top: 10px;
}

.scriptStatus_label {
  color: wallaby.$theme-dark-9;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 2px;
}

.scriptStatus_description {
  align-items: center;
  display: flex;
  font-size: 16px; // Matine override
  margin-bottom: 0 !important; // Mantine override
  margin-top: 0; // Matine override
}

// disabled state

.scriptStatus_label[data-disabled] {
  color: wallaby.$theme-gray-5;
}

.scriptStatus_label[data-disabled] + .scriptStatus_description {
  color: wallaby.$theme-gray-5 !important;
}
