
          @use '@scss/all' as wallaby;
        
.nameListing {
  align-items: center;
  display: flex;
  padding-left: 5px;
  width: 100%;
}

// for documents the container handles the padding
.nameListing_doc {
  padding: 0;
}

.nameListing_icon {
  font-size: 13px;
  margin-right: 5px;

  &.nameListing_icon___disabled {
    color: wallaby.$d3-light-gray;
  }
}

.nameListing_input {
  font-family: 'Lato', sans-serif;
  padding: 4px 8px;
  width: 100%;
}
