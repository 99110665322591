
          @use '@scss/all' as wallaby;
        
.dashWrapper {
  background-color: #eee;
  height: 100%;
  overflow: auto;
}

.dash {
  margin: 0 auto;
  max-width: 1000px;
  min-width: 420px;
  padding: 20px;
}

.dash_breadcrumb {
  margin: 5px auto auto 10px;
}

.dash_header {
  font-size: 18px;
  margin: 0 0 10px;
}

.dash_buttonrow {
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;

  &.dash_buttonrow___midscreen {
    // should be applied at 500px
    grid-row-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
  }

  &.dash_buttonrow___smallmidscreen,
  &.dash_buttonrow___smallscreen {
    // should be applied at 650px and 500px, respectively
    grid-row-gap: 10px;
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr 1fr;
  }

  &.dash_buttonrow___four {
    grid-column-gap: 10px;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    &.dash_buttonrow___midscreen,
    &.dash_buttonrow___smallmidscreen {
      // should be applied at 800px and 650px, respectively
      grid-row-gap: 10px;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr;
    }

    &.dash_buttonrow___smallscreen {
      // should be applied at 500px
      grid-row-gap: 10px;
      grid-template-columns: auto;
      grid-template-rows: 1fr 1fr 1fr 1fr;
    }
  }
}
