
          @use '@scss/all' as wallaby;
        
.createdoc {
  background-color: wallaby.$light-white;
  border: 0;
  border-radius: 3px;
  box-shadow: 0 0 10px rgb(171 171 171 / 0.25);
  color: wallaby.$d2-dark-gray;
  height: 100%; // take up the entire grid container
  padding: 15px;
  text-align: left;
  width: 100%;

  &:hover {
    background-color: wallaby.$l4-light-gray;
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.createdoc_row {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.createdoc_column {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.createdoc_label {
  color: wallaby.$d1-purple;
  font-weight: 700;
  margin-bottom: 3px;
}

.createdoc_icon {
  color: wallaby.$d1-purple;
}
