@use '../wombat-scss/partials/variables' as wallaby;

/*
  Mantine has an out of box/recommended GlobalStyles and CssReset (a bit like normalize).
  However, we have relied on different global styles and different css reset values so here we are
  overriding some of Mantine's default's

  Thus, these three are applied in order:

  1. https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/NormalizeCSS.tsx
  2. https://github.com/mantinedev/mantine/blob/master/src/mantine-styles/src/theme/GlobalStyles.tsx
  3. The styles in this file
*/

body {
  background-color: var(--mantine-color-gray-2);
  color: var(--mantine-color-dark-9);
  font-family: wallaby.$base-font-family;
  line-height: 1.2;
}

// Hide the tooltip of a menu target button when the menu
// or popover is open (fixes a Safari-specific issue)
[aria-expanded='true'] > .mantine-Tooltip-tooltip {
  display: 'none';
}
