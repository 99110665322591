.fade-in-animation {
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-out;
  opacity: 1;
}

.fade-out-animation {
  animation-iteration-count: 1;
  animation-name: fadeOutOpacity;
  animation-timing-function: ease-out;
  opacity: 0;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
