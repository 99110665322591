
          @use '@scss/all' as wallaby;
        
.explorerHeaderButton {
  border-radius: 3px;
  color: wallaby.$theme-gray-9;
  padding: 4px 6px;

  &:hover {
    background-color: wallaby.$theme-gray-3;
  }
}
