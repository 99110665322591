
          @use '@scss/all' as wallaby;
        
// This MUST match the height property in DocumentList.tsx
$document-listing-height: 48px;

.doc_link {
  color: wallaby.$d2-dark-gray;
  flex: 1;

  &:hover {
    // default link overrides
    color: wallaby.$d2-dark-gray;
    text-decoration: none;
  }
}

.doc {
  @include wallaby.listing-background(doc___selected);

  align-items: stretch;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  height: $document-listing-height;
  margin: 0 5px;
  max-height: $document-listing-height;
  padding: 5px 2px 5px 5px;
  position: relative;

  .doc_info {
    flex: 1;
    // leave enough room for the dot dot dot menu
    padding-right: 20px;
  }

  .doc_menuWrapper {
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 20px;
  }

  // hide the dot-dot-dot by default if not expanded
  &:not(:hover) .doc_menuWrapper > *:not([data-expanded]) {
    opacity: 0;
  }
}

.doc_icon {
  // either folder or doc type icon
  font-size: 13px;
  margin-right: 5px;
}

.doc_title {
  -webkit-box-orient: vertical;
  // either folder name or script title
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.doc_name {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 2px;
  width: 100%;
}

.doc_meta {
  @extend .doc_title;
  // `last edited by` info
  color: wallaby.$base-mid-gray;
  font-size: 14px;
}
