/*
these styles exist to smooth over weirdness between conflicting legacy css and new modual scss styles.
it shouldn't exist forever.
once scss is fully implemented, we should delete this.
*/

/*
each entry should have an explanation for why it's needed.
*/

/*
SIGN IN
*/

.outer-wrapper {
  .form-inner {
    form {
      // this sign in form needs a 100% width and other forms don't,
      // which we should give it when it has its own special sass class
      width: 100%;
    }

    label {
      font-weight: bold; // `sign in` and `forgot password` input labels
    }
  }
}

/*
HEADER
*/

.logo-container {
  // fixes the header on small/medium screens until new sass classes can be added

  .logo-left {
    @media screen and (max-width: 800px) {
      display: none;
    }
  }

  .logo-left--mobile {
    display: none;
    max-width: 30px;

    @media screen and (max-width: 800px) {
      display: block;
    }
  }

  @media screen and (max-width: 800px) {
    width: 80px;
  }
}

/*
DASH
*/

.drop-down-ul {
  .drop-down-li {
    &:first-of-type {
      margin-top: 5px;
    }
  }
}

/*
NAV
*/

.l-nav {
  // counteracts .top-nav-row's margin-bottom: 30px;
  margin-top: -10px;
}

.o-dropdown {
  &.is-open {
    .o-dropdown__list {
      .o-dropdownlist__item {
        // override's skeleton's li's 1rem margin-bottom
        margin-bottom: 0;
      }
    }
  }
}

.c-overlay {
  // override's .sticky-nav's 100 z-index
  z-index: 101 !important;
}

.large-link {
  .icon {
    // makes "create new" links on dashboard blue
    color: $d3-purple;
  }
}

.icon {
  &.ellipsis-menu {
    // for the script card menu, overrides .icon's small font size
    font-size: 18px;
  }

  &.folder-ellipsis-menu {
    // same as above, but for folder menu specifically
    font-size: 18px;
  }
}

/*
SCRIPT TOOLBAR
*/

.nav-btn {
  .icon {
    &.nav-icon {
      // default .icon color makes the script toolbar icons too light to see clearly
      color: $base-mid-gray;
    }
  }
}

.selected-nav-btn {
  .icon {
    &.nav-icon {
      // gives dark color to selected toolbar buttons
      color: $d1-blue-gray;
    }
  }
}

// name version modal (will affect other semantic forms inside modals)

.modal-bottom {
  form {
    // centers input field in name version modal,
    // will also affect legacy namespaced modals with forms
    align-items: center;
    display: flex;
    flex-direction: column;

    .form-field {
      &.form-field--belownote {
        // removes top-margin of input so error note can appear directly above if needed
        margin-top: 0;
      }
    }
  }
}

/*
MODALS
*/

.icon {
  &.modal-close-i {
    // overrides sass .icon class for modal close button
    color: $base-mid-gray;
    font-size: 18px;
  }
}

.modal-bottom {
  p {
    // "cancel" modal link lives in a p, and the default p margin creates way too much
    // space at the bottom of the modal
    margin: 0;
  }

  .form-field {
    // versions modal -
    // puts space between top modal paragraph and form field
    margin: 15px auto;
  }
}

/*
FORMS
*/

form {
  // centering buttons without centering form fields
  display: flex;
  flex-direction: column;
  // skeleton override
  margin-bottom: 0;

  // don't override any mantine input (checkbox, text, etc.)
  // stylelint-disable-next-line selector-class-pattern
  input:not([class*='mantine-']) {
    margin-top: 5px;
  }
}

.form-inner {
  .form-field {
    // the default 300px width looks wrong here on the sign in page
    width: 100%;
  }
}

.form-inner {
  // styles to deal with the niche margin and padding issues on the sign in page

  .error-note {
    // because "error-note" isn't also getting "note" class
    border-radius: 3px;
    padding: 4px 8px;
  }

  .form-field {
    margin-top: 5px;

    &.first-of-type {
      margin-top: 10px;
    }
  }
}
