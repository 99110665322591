
          @use '@scss/all' as wallaby;
        
.org-detail-staff-page {
  width: 1000px;

  .option-setting {
    display: flex;
    padding: 5px 0;

    label {
      margin-left: 20px;
    }
  }
}
