@use './theme-colors' as colors;

@mixin flex-column-justify-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@mixin fullvh {
  height: 100vh;
  overflow: hidden;
}

@mixin overflow-hidden {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

// set backgrounds for :hover, active, and active:hover
// (pass in the class used for active)
@mixin listing-background($active-selector) {
  cursor: pointer;

  &:hover {
    background-color: colors.$theme-gray-1;
  }

  &.#{$active-selector} {
    background-color: colors.$theme-violet-0;

    &:hover {
      background-color: darken(colors.$theme-violet-0, 2%);
    }
  }
}
