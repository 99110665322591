
          @use '@scss/all' as wallaby;
        
.shortcuts_container {
  // Mantine Container overrides
  margin: 0;
  padding: 0;
}

.shortcuts_title {
  margin-left: 8px !important;
}

.shortcuts_shortcutCol {
  text-align: right;
  white-space: nowrap;
}

.shortcuts_keys {
  background-color: wallaby.$theme-gray-2;
  border-radius: 3px;
  padding: 2px 8px;
}
