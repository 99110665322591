// toggle container
// because toggles often live in buttons or other containers that need toggle styles
// e.g. buttons on hover

.c-toggle-container:hover {
  .o-toggle {
    color: $d2-dark-gray;
  }
}

// toggles

.o-toggle {
  font-size: $t4-font-size;

  &.o-toggle--off {
    color: $d1-mid-gray;

    &:hover {
      color: $d2-dark-gray;
    }
  }

  &.o-toggle--on {
    color: $success-color;
  }

  &.o-toggle--labeled {
    // for toggles attached to a labled button
    font-size: $s1-font-size;
    margin-right: 6px; // places space between the icon and the button's label
  }
}
