// toggles with labels inside them
// (as opposed to on/off toggles)
// e.g. `Full Text` / `Changes Only`

.c-viewtoggle {
  background-color: $light-white;
  border-radius: 3px;
  display: flex;
  padding: 4px;
}

.c-viewtoggle__button {
  background-color: $light-white;
  border: 0;
  border-radius: 3px;
  margin: auto 2px;
  padding: 2px 4px;

  &:hover {
    background-color: $d2-light-gray;
  }

  &:first-of-type {
    margin: 0;
  }

  &.c-viewtoggle__button--selected {
    background-color: $d1-dark-gray;
    color: $light-white;

    &:hover {
      background-color: $d1-dark-gray;
      color: $light-white;
    }
  }
}
