
          @use '@scss/all' as wallaby;
        
.settings_container {
  min-width: 450px;
  padding: 0 30px;
  width: 100%;
}

.settings_input {
  flex-grow: 1;
}
