
          @use '@scss/all' as wallaby;
        
.root {
  border-radius: 3px;
  font-weight: 400;

  &[data-size='sm'] {
    font-size: 15px;
    height: 31px;
    min-width: 80px;
    padding-left: 12px;
    padding-right: 12px;
  }

  &[data-variant='danger'] {
    background-color: unset;
    border: 1px var(--mantine-color-red-6) solid;
    color: var(--mantine-color-red-6);

    &:hover {
      background-color: var(--mantine-color-red-0);
    }
  }
}

.root:disabled {
  &[data-disabled='true'] {
    background-color: var(--mantine-color-gray-4);
    color: var(--mantine-color-white);
    cursor: unset;

    &[data-variant='danger'] {
      background-color: unset;
      border: 1px wallaby.$error-muted-color solid;
      color: wallaby.$error-muted-color;
    }
  }
}
