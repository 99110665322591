
          @use '@scss/all' as wallaby;
        
.item {
  padding: 10px;

  &:hover {
    background-color: wallaby.$theme-violet-2;
  }
}

.selected {
  background-color: wallaby.$theme-violet-1;
}
