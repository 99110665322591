
          @use '@scss/all' as wallaby;
        
.expandIcon_wrapper {
  text-align: center;
}

.expandIcon {
  color: wallaby.$theme-gray-7;
  display: flex;
  font-size: 10px;
  justify-content: center;
  transition: transform 0.15s ease-out;
  width: 100%;
}

.expandIcon___rotate {
  transform: rotate(90deg);
}

.expandIcon_btn {
  min-width: unset; // mantine reset
  padding: 5px 8px;
  width: unset; // mantine reset

  &:hover {
    background-color: wallaby.$theme-gray-3;
    color: wallaby.$theme-dark-9;
  }
}

.expandIcon_folder:hover {
  background-color: wallaby.$theme-gray-1;
}
