
          @use '@scss/all' as wallaby;
        
.subheader {
  color: wallaby.$d1-mid-gray;
  display: flex;
  font-family: wallaby.$base-font-family;
  font-size: 13px;
  font-weight: bold;
  margin: 10px 0 0;
  padding: 10px 4px 4px 8px;
  text-transform: uppercase;
}
