
          @use '@scss/all' as wallaby;
        
.search_root {
  flex: 1;
}

.search_input {
  font-size: 15px;
}

.search_inputGroup {
  width: 100%;
}

// to appear on top of the temporary announcement pulse animation
.search_button {
  z-index: 1;
}
