
          @use '@scss/all' as wallaby;
        
.wrapper {
  align-content: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 100;

  &.fadeIn {
    animation-duration: 200ms;
    animation-fill-mode: forwards;
    animation-iteration-count: 1;
    animation-name: fadeInOpacity;
    animation-timing-function: ease-in;
    opacity: 0;
  }
}

.overlay {
  background-color: rgba(0, 0, 0, 0.35);
}

.fullScreen {
  position: fixed;
}
