.s-form {
  &.s-form--inline {
    display: inline;
  }

  .s-form__fieldset {
    display: block;
    margin-top: 20px;
  }

  .s-form__button {
    display: inline;
  }
}

.s-input {
  width: unset;

  &.s-input--inline {
    margin-right: 10px;
  }

  .s-input--radio {
    margin: 0 5px 0 20px;
  }
}

.s-label {
  &.s-label--inline {
    margin-right: 20px;
  }

  &.s-label--radio {
    display: inline;
  }
}
