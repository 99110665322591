/*
SETTINGS PAGE
*/

/*
these are stopgap styles until we can do a larger settings refactor
*/

// sidenav

.c-sidenav {
  .c-sidenavsection__label {
    margin-bottom: 2px;
  }

  .c-sidenavsection__link {
    align-items: center;
    background-color: $light-white;
    border-left: 1px solid $d1-light-gray;
    border-right: 1px solid $d1-light-gray;
    border-top: 1px solid $d1-light-gray;
    display: flex;
    font-size: $s1-font-size;
    font-weight: $heading-font-weight;
    justify-content: space-between;
    padding: 8px;

    &:hover {
      color: $d2-dark-gray;
      text-decoration: none;
    }

    &.is-selected {
      background-color: $d1-purple;
      color: $light-white;

      &:hover {
        background-color: $d1-purple;
        cursor: default;
      }

      .icon {
        color: $light-white;
        opacity: 0.5;
      }
    }

    &:last-of-type {
      border-bottom: 1px solid $d1-light-gray;
    }

    &:hover {
      background-color: $l3-light-gray;
      cursor: pointer;
    }

    .icon {
      color: $d3-light-gray;
      font-size: $s2-font-size;
    }
  }
}
