// legacy notifications

.note {
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 15px;
}

.closeable-note {
  justify-content: space-between;
}

.error-note {
  background-color: #ffe7e7;
  color: #f00;
  padding: 0 8px;
}

.success-note {
  background-color: #d3f8cd;
  color: #159a00;
}

.neutral-note {
  background-color: #d1eeff;
  color: #1789cd;
}

.close-note-icon {
  margin-top: -15px;
}

.close-note-icon:hover {
  color: #555;
  cursor: pointer;
}

.note-icon {
  padding-right: 10px;
}

.iconic-close-icon {
  align-self: flex-end;
}
