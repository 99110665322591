
          @use '@scss/all' as wallaby;
        
.banner {
  background-color: wallaby.$note-light-color;
  border-bottom: 1px #d6d6d6 solid;
  color: wallaby.$note-color;
  padding: 10px;
}

.banner.warn {
  background-color: wallaby.$warning-light-color;
  color: wallaby.$warning-dark-color;
}
