
          @use '@scss/all' as wallaby;
        
.membertable_action {
  // could be THEMEd (probably)
  text-align: right;
}

:global(.membertable_row___pending) {
  background-color: #f6f6f6;
}

:global(.membertable_row___pending td) {
  background-color: #f6f6f6;
  padding: 10px;
}
