
          @use '@scss/all' as wallaby;
        
.stepper_steps {
  align-items: center;
  margin: 0 auto;
  max-width: 250px;
}

.stepper_stepIcon {
  background-color: wallaby.$theme-dark-3;
  border-color: transparent;
  color: wallaby.$light-white;
  font-size: 14px;
}

.stepper_stepIcon {
  &[data-progress] {
    background-color: wallaby.$light-white;
    border-color: wallaby.$theme-violet-5;
    color: wallaby.$theme-dark-9;
  }
}

.stepper_stepCompletedIcon svg {
  height: 15px;
  width: 15px;
}

.stepper_separator {
  background-color: transparent;
  border-bottom: 3px dotted wallaby.$theme-dark-3;
  margin-left: 8px;
  margin-right: 8px;

  &[data-active] {
    border-bottom: 3px dotted wallaby.$theme-violet-5;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.stepper_stepBody {
  margin-left: unset;
}
