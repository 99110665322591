
          @use '@scss/all' as wallaby;
        
// this class manages the side-by-side layout as a flexbox
// with flex-direction set by the layout rows/columsn value
.sideBySide {
  display: flex;
  flex: 1;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  &.rows {
    flex-direction: column;
  }

  &.columns {
    flex-direction: row;
  }
}

// React-resizable needs this extra wrapper because
// <Resizable> can't live in a flex parent
.reactResizableWrapper {
  display: block;
}

// This is the wrapper around the first child. It's
// the one that's directly resizable, the second child
// just fills the remaining space
.reactResizable {
  position: relative;
  width: 100%;

  // We pad the right or bottom of the first child
  // to make room for the grab handle
  &.rows {
    padding-bottom: var(--grab-handle-size);
  }

  &.columns {
    padding-right: var(--grab-handle-size);
  }
}

// the two content panes fill the available space, clip overflow
// and expect a single child each to fill it
.childWrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;

  > :first-child {
    height: 100%;
    width: 100%;
  }
}
