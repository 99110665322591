// these styles are utilized on screen, but wombat-lite is injected by the API during printing
@use '../../wombat-scss/partials/variables' as *;

.o-revision {
  align-items: center; /* fix for inline span height not matching container */
  display: flex;
  font-weight: normal; /* don't pick up the font weight of the .o-block-element for the revision mark */
  position: absolute;
}

// the -s. (we want these red even when they are *s in pdfs)
.o-revision__removed {
  color: $error-color;
}

@media screen {
  .o-script-content__revised {
    background: #acf2bd;
  }

  // the +s (we want them black when they are *s in pdfs)
  .o-revision__revised {
    color: $success-color;
  }

  .o-revision__removed::after {
    content: '-';
  }

  .o-revision__revised::after {
    content: '+';
  }
}

@media print {
  // black regardless of block coloring
  .o-revision__revised {
    color: #000;
  }

  // red or black, depending on monochrome user pref
  .o-revision__removed {
    color: var(--link-color, $error-color);
  }

  .o-revision__removed::after,
  .o-revision__revised::after {
    content: '*';
  }
}
