
          @use '@scss/all' as wallaby;
        
.darkpage {
  background-color: var(--mantine-color-dark-6);
  color: wallaby.$light-white;
  max-width: unset;
  min-height: 100vh;
  padding: 40px 20px;
}

.darkpage_logo {
  margin: 0 auto 40px;
  width: 240px;
}

.darkpage_card {
  background-color: #2f3043;
  border-top: 6px solid #835bc1;
  box-shadow: 0 0 10px #191a24;
  color: wallaby.$light-white;
  padding-bottom: 1.5em;
}

.darkpage_link {
  color: wallaby.$l2-purple;

  &:hover {
    color: wallaby.$light-white;
  }
}
