
          @use '@scss/all' as wallaby;
        
.pagination {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
}

.pagination_label {
  text-decoration: underline;
}

.pagination_link___first {
  visibility: hidden;
}

.pagination_icon {
  font-size: 12px;
  margin-left: 5px;
  padding: 7px 0 5px;

  &:first-of-type {
    margin-left: unset;
    margin-right: 5px;
  }
}

// pagination inside a bordered box

.pagination___box {
  @extend .pagination;
  background-color: wallaby.$l4-light-gray;
  border: 2px solid wallaby.$l2-light-gray;
  margin: 0;
  padding: 10px;
}

// absolutely positioned at bottom

.pagination___snapshots {
  @extend .pagination___box;
  border: 0;
  bottom: 0;
  padding: 5px 10px;
  position: sticky;
}
