
          @use '@scss/all' as wallaby;
        
.onboardingPage {
  background-color: wallaby.$theme-dark-6;
  height: 100%;
  overflow: auto;
  padding-top: 40px;
  position: relative;
}

.onboardingPage_contents {
  margin: auto;
  width: 400px;
}

// same styles as the links in onboardingLinks.module.scss
// could be DRYer if we could consolidate them somehow
.onboardingPage_link {
  color: wallaby.$theme-violet-2;

  &:hover {
    color: wallaby.$light-white;
  }
}
