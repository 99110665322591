@use '../../scss/_theme-colors.scss';
/*
COLORS
*/

// dark grays
$d2-dark-gray: #0d0d13; // default body text color
$d1-dark-gray: #282828; // background for tooltips, dark dropdowns, comments, floating context menus
$base-dark-gray: #40414c; // dropdown background on hover
$l1-dark-gray: #4f4f4f; // borders for elements with dark backgrounds
$l2-dark-gray: #5e5e5e; // medium-light text color

// mid grays
$d1-mid-gray: #74757b; // light text color
$base-mid-gray: #898989; // dark icons
$l1-mid-gray: #919297; // deemphasized text on dark backgrounds

// light grays
$d3-light-gray: #c1c1c1; // icons, borders, very light text (does not pass WCAG), disabled button backgrounds and borders
$d2-light-gray: #cdcdcd; // disabled borders
$d1-light-gray: #d6d6d6; // borders, button hover backgrounds, selected state backgrounds
$base-light-gray: #e7e7e7; // light borders
$l1-light-gray: #ebebeb; // borders and hover backgrounds
$l2-light-gray: #eee; // backgrounds
$l3-light-gray: #f2f2f2; // header backgrounds, footer backgrounds, hover backgrounds, app background
$l4-light-gray: #f9f9f9; // background
$light-white: #fff;

// blue-grays
$d1-blue-gray: #252635; // dark hover background
$base-blue-gray: #535360; // HRs for dark backgrounds, selected and hover states for dark backgrounds, gray badges, tooltip borders
$l1-blue-gray: #e9f5ff; // import modal status background

// yellows
$highlight-yellow: #fffb94;
$highlight-yellow-dark: #d7d144; // better contrast against dark backgrounds

// purples
$d4-purple: #441589;
$d3-purple: #61369f; // links
$d2-purple: #6f3db8;
$d1-purple: #8252c6; // primary purple
$base-purple: #8e66c8;
$l1-purple: #a27fd3;
$l2-purple: #b89ade; // light purple links
$l3-purple: #d3beee;
$l4-purple: #f1e6ff;

// OVERLAYS
$overlay-color: rgba(0, 0, 0, 0.7);

// NOTIFICATION COLORS

// success
$success-color: #118000;
$success-light-color: #e1fbdf;
$success-muted-color: #a5d09e;

// error
$error-color: #f00;
$error-dark-color: #b30000;
$error-light-color: #ffe7e7;
$error-muted-color: #e6b2b2;

// warning
$warning-color: #ffd02f;
$warning-dark-color: #876a03;
$warning-light-color: #fff1bd;
$warning-muted-color: #dabe5f;

// neutral
$note-color: #1376ae;
$note-light-color: #ebf8ff;
$note-muted-color: #87c0e2;

// DIFFS
$deletion-diff-light: #ffd0d0;
$deletion-diff-dark: #ff0404;
$addition-diff-light: #acfead;
$addition-diff-dark: #0db00f;

// USER COLORS
$red-user-color: #d50000;
$indigo-user-color: #483d8b;
$teal-user-color: #00a4a6;
$gold-user-color: #b8860b;
$orange-user-color: #e65722;
$blue-user-color: #1460aa;
$brown-user-color: #804028;
$gray-user-color: #939393;

// ELEMENT COLORS
$bracket-color: #cd3c8c;
$general-color: #ad611f;

// SCRIPT STAUS
$script-open-color: theme-colors.$theme-green-8;
$script-limited-color: theme-colors.$theme-orange-8;
$script-private-color: theme-colors.$theme-blue-8;

/*
TYPOGRAPHY
*/

// headings
$heading-font-family: 'Montserrat', sans-serif;
$heading-font-weight: 700;
$heading-letter-spacing: -1px;
$t1-font-size: 30px;
$t2-font-size: 26px;
$t3-font-size: 20px;
$t4-font-size: 18px;
$base-font-size: 16px;

// body
$base-font-family: 'Lato', sans-serif;
$base-font-weight: 400;
$base-font-size: 16px;
$s1-font-size: 15px;
$s2-font-size: 14px;
$s3-font-size: 12px;

// script typography
$script-font-family: 'Courier Prime', courier, monospace;
$script-line-height: 1;
$script-font-size: 12pt;

/*
LAYOUT
*/

// screen widths
$desktop-md: 1500px;
$desktop-small: 1400px;
$desktop-xs: 1300px;
$desktop-xxs: 1100px;
$tablet-xl: 900px;
$tablet-l: 700px;

// this is an 8.5" page plus enough extra room on the sides for
// for things like remote user avatars and lock icons
$minimum-editor-width: 912px;
