
          @use '@scss/all' as wallaby;
        
.prefModal_tab {
  font-size: 16px;
}

.prefModal_group {
  @media screen and (max-width: 700px) {
    flex-direction: column; // this makes the left edge of the colum flush
    gap: 40px;
  }
}

.prefModal_group___selects {
  @media screen and (max-width: 850px) {
    flex-direction: column; // this makes the left edge of the colum flush
    gap: 40px;
  }
}

.prefModal_stack,
.prefModal_innerInput {
  width: 180px;
}

.prefModal_innerInput___page {
  width: 50px;
}

.prefModal_scrollingContent {
  overflow-y: auto;
}
