
          @use '@scss/all' as wallaby;
        
.permissionSettings_page {
  padding: 0 30px;
}

.accordion {
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-bottom: 0;

  &:last-of-type {
    border-bottom: 1px solid #d6d6d6;
  }
}

.accordion_panel {
  background-color: wallaby.$theme-gray-0;
}

.accordion_content {
  padding-top: 10px;
}

.accordion_item {
  border-bottom: 0;
}
