/*  generic spacing and wrappers */

.outer-wrapper {
  background-color: #252635;
  min-height: 100vh;
}

.medium-wrapper {
  max-width: 600px;
}

.centered {
  display: table;
  margin-left: auto;
  margin-right: auto;
}

.centered-row {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* centers a single item within a skeleton row,
    e.g. load more button */
  margin: 0 auto;
}

.p-wrapper p:last-child {
  /* e.g. notification w/ multiple lines */
  margin-bottom: 0;
}

/*  typography  */

h1:not([class*='mantine-']) {
  color: #0d0d13;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 700;
  letter-spacing: 0.5px;
}

.light-h1 {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.5;
  margin: 30px 0;
}

h3:not([class*='mantine-']) {
  color: #0d0d13;
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
}

.display-header {
  color: #8252c6;
  font-family: 'Montserrat', sans-serif;
  font-size: 48px;
  font-weight: 700;
  margin: 5px;
}

/* stylelint-disable-next-line selector-class-pattern */
ul:not(.mantine-List-root) {
  list-style: none;
}

ul:not([class*='mantine-']) ul {
  margin: 0;
}

.centered-text {
  text-align: center;
}

/*  typography colors  */

.muted-blue-text {
  color: #87c0e2;
}

.muted-red-text {
  color: #e6b2b2;
}

/*  images  */

.dark-logo {
  margin-bottom: 20px;
  margin-top: 50px;
  max-width: 280px;
}

.tv-img {
  margin-top: 100px;
  width: 250px;
}

/* common objects */

* {
  &:focus {
    outline: none;
  }
}

// yuck. we need to get rid of this declaration altogether
p:not([class*='mantine-']):not(.o-block-element) {
  margin-bottom: 20px;
}

/* stylelint-disable-next-line selector-class-pattern */
ul:not(.mantine-List-root) {
  list-style-type: none;
}

hr {
  border: 0;
  border-bottom: 1px solid $d1-light-gray;
  height: 0;
  margin: 0 auto;
}

.icon {
  color: $d3-light-gray;
  font-size: $s1-font-size;

  &.has-toggle-on {
    color: $success-color;
    font-size: $base-font-size;
  }
}

input:not([class*='mantine-']) {
  background-color: $light-white;
  border: 1px solid $d1-light-gray;
  border-radius: 3px;
  font-weight: normal;
  padding: 6px 10px;

  &:focus {
    border: 1px solid $d1-purple;
  }

  &::placeholder {
    color: $d1-mid-gray;
  }

  @media screen and (max-width: 600px) {
    width: 260px;
  }
}

// ancient media queries

@media (max-width: 549px) {
  /* sign in / sign up */

  .dark-logo {
    margin: 30px auto 10px;
    max-width: 200px;
  }

  .form-inner {
    width: 260px;
  }
}
