
          @use '@scss/all' as wallaby;
        
.fancyMenuItem_selected {
  background-color: wallaby.$theme-violet-5;

  // normal mouse hover
  &:hover,
  // this is the selector mantine applies when using the arrow keys
  &[data-hovered='true'] {
    background-color: wallaby.$theme-violet-7;
  }
}
