
          @use '@scss/all' as wallaby;
        
.snapshotHistory {
  background: wallaby.$light-white;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
}

.snapshotHistory_results {
  flex: 1;
  overflow-y: auto;
}
