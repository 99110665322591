.o-elementwrap i,
.o-elementmenu i,
.o-elementicon i {
  // !important for browser extensions that change fonts
  font-family: 'icomoon' !important;
  // better for rendering
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: none;
  text-transform: none;
}

// element icon button

.o-elementicon {
  background-color: $light-white;
  border: 0;
  border-radius: 3px;
  height: auto;
  padding: 8px 15px 6px;
  z-index: map-get($zindex, 'editor-ui');

  &.o-hovertooltip[data-tooltip]::after {
    left: -110px;
    top: -35px;

    @media screen and (max-width: $desktop-small) {
      // prevents left cutoff on smaller screens
      left: 0;
    }
  }

  &:hover {
    background-color: $d1-purple;

    .o-elementicon__wrap i {
      color: $light-white;
    }
  }
}

.o-elementwrap {
  display: flex;
  flex-direction: row;
  height: 0;
  justify-content: flex-end;

  &.is-invisible {
    display: none; // overrides flex
  }

  .o-elementicon {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: map-get($zindex, 'editor-ui');

    &:hover {
      color: $d3-purple;
      cursor: pointer;
    }
  }
}
