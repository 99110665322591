//
// LEGACY SECOND NAV //
//

// stickiness

.sticky {
  background-color: #f2f2f2;
  margin: 0;
  padding: 0 0 5px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}

// nav

.sec-nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 40px;
  margin-top: -80px;
}

.left-sec-nav {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.back-icon {
  color: #acacac;
  font-size: 18px;
}

.back-icon:hover {
  color: #4a90e2;
  cursor: pointer;
}

.sec-nav-title {
  display: flex;
  height: 28px;
}

.script-title {
  display: inline-block;
  max-width: 440px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.small-icon {
  color: #acacac;
  font-size: 14px;
  margin-left: 8px;
}

.small-icon:hover {
  color: #4a90e2;
  cursor: pointer;
}

.right-sec-nav {
  display: flex;
  flex-grow: 1;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.icon-link-i {
  color: #acacac;
  font-size: 14px;
  padding-right: 8px;
}
