//
// LEGACY MODALS //
//

.fixed-scroll {
  position: fixed;
  width: 100%;
  z-index: 1000; /* places modal content above other page content */
}

.modal-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.modal-overlay {
  background-color: rgba(98, 98, 98, 0.6);
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 5000; /* places modal above .fixed-scroll */
}

.modal-whole {
  box-shadow: 0 2px 4px #aeaeae;
  display: flex;
  flex-direction: column;
  font-size: $base-font-size;
  margin-top: 100px;
  max-width: 500px;
  position: fixed;
}

.modal-whole-lg {
  /* must be below .modal-whole */
  width: 60%;
}

.modal-top {
  align-items: center;
  background-color: #f2f2f2;
  border-bottom: 1px solid #d3d3d3;
  border-radius: 3px 3px 0 0;
  display: flex;
  height: 25px;
  justify-content: space-between;
  padding: 20px 15px;
}

.modal-title {
  color: #0d0d13;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin-bottom: 15px; // because modal-title is wrapped in a `p` tag, it will fall back on a 20px margin-bottom unless specified otherwise
  max-width: 500px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.modal-close-i {
  font-size: $base-font-size;
  margin-top: -2px;

  &.o-icon {
    // overrides o-icon color, which is too light for this contect
    // TODO: remove `o-icon` from its use alongside `modal-close-i`
    // BIGGER TODO: refactor these legacy modals to use the newer modal classes
    color: $base-blue-gray;
  }
}

.modal-close-i:hover {
  color: #0d0d13;
  cursor: pointer;
}

.modal-bottom {
  align-items: center;
  background-color: #fff;
  border-radius: 0 0 3px 3px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.modal-body {
  align-self: flex-start;
  margin-bottom: 20px;
}

// move script modal

.modal-middle {
  background-color: #fff;
  font-size: 18px;
  max-height: 320px;
  overflow: auto;
}

.modal-folder-wrapper {
  padding: 10px 15px;
}

.modal-folder-wrapper .modal-folder-wrapper {
  padding: 0 0 0 30px;
}

.modal-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding: 5px 12px;
}

.modal-row:hover {
  background-color: #f3f3f3;
  border-radius: 3px;
  cursor: pointer;
}

.expand-collapse-i {
  color: #4a90e2;
  font-size: 16px;
}

.expand-collapse-i:hover {
  color: #366dae;
}

.expand-collapse-spacer {
  width: 25px;
}

.folder-i {
  color: #cacaca;
  font-size: 18px;
  margin-right: 10px;
}

.modal-gray-bottom {
  background-color: #f3f3f3;
  border-radius: 0 0 3px 3px;
  border-top: 1px solid #dfdfdf;
}

.modal-row-selected,
.modal-row-selected:hover {
  /* must be below .modal-row */
  background-color: #8252c6;
  border-radius: 3px;
  color: #fff;
}

.modal-row-selected .folder-i {
  color: #fff;
}

.modal-row-selected .expand-collapse-i,
.modal-row-selected .expand-collapse-i:hover {
  color: #fff;
}
