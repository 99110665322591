// DROPDOWN COMPONENT
// reuses the `o-dropdown` styles in a way that doesn't break BEM rules and is more modular
// TODO: deal with o-dropdown, which is in use in the app

/* stylelint-disable */

// disabling linting because dropdowns are very over-nested, and there's no quick or elegant way to change that
// TODO: retwrite and restructure dropdowns to be more modular

.c-dropdown {
  display: flex;
  position: relative;

  &.c-dropdown--left.is-open {
    // places dropdown list flush with the left edge of its parent
    align-items: flex-start;
  }

  &.c-dropdown--center.is-open {
    // centers dropdown list under its parent
    align-items: center;
  }

  &.c-dropdown--right.is-open {
    // centers dropdown list under its parent
    align-items: flex-end;
  }

  &.c-dropdown--dotted {
    // dotted line to indicate interaciveness

    .c-dropdown__label {
      border-bottom: 1px dashed $d2-dark-gray;
    }
  }

  .c-dropdown__item {
    padding: 4px 8px;
    width: 100%;

    &.c-dropdown__item--sub {
      // for demphasized or child list items
      font-size: 13px;
    }

    &.c-dropdown__item--truncated {
      // e.g. hidden breadcrumb dropdown
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .c-dropdown__button {
    // <button> overrides
    padding: 0;

    &:hover {
      cursor: pointer;
    }

    &.c-dropdown__button--as-link {
      background-color: transparent;
      color: $d3-purple;
    }

    &.c-dropdown__button--disabled {
      // <button> overrides
      color: $d1-mid-gray;

      &:hover {
        // <button> overrides
        background-color: $light-white;
        cursor: default;
      }
    }
  }

  .c-dropdown__caret {
    margin-left: 8px;
  }

  .c-dropdown__item,
  .c-dropdown__button {
    // <button> overrides
    background-color: $light-white;
    border: 0;
    border-radius: 0;
    color: $d2-dark-gray;
    font-family: $base-font-family;
    font-size: $s1-font-size;
    height: auto;
    margin-bottom: auto; // skeleton <li> override
    text-align: left;
    text-transform: none;

    &:hover {
      background-color: $l3-light-gray;
      cursor: pointer;
      text-decoration: none; // link override
    }

    &.c-dropdown__item--disabled {
      color: $d1-mid-gray;

      &:hover {
        background-color: $light-white;
        cursor: default;
      }
    }
  }

  .c-dropdown__link {
    // icon-accomodating layout
    align-content: center;
    align-items: center;
    // link overrides
    color: $d2-dark-gray;
    display: flex;
    width: 100%;

    &:hover {
      // link override
      text-decoration: none;
    }
  }

  .c-dropdown__icon {
    // layout that allows icons of differing widths to appear centered
    display: flex;
    font-size: $s2-font-size;
    justify-content: center;
    margin-right: 6px;
    width: 20px;
  }

  .c-dropdown__shortcut {
    // for keyboard shortcuts that correspond to dropdown items
    color: $d1-mid-gray;
    display: flex;
    font-size: $s1-font-size;
    justify-content: center;
    margin-left: 8px; // creates buffer so shortcut isn't squished with the longest list item
  }

  &.is-open {
    // TODO: remove layout styles and replace with `l-box` classes
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .o-hovertooltip[data-tooltip]:hover::after {
      // so corresponding tooltip doesn't appear beneath open dropdown
      display: none;
    }

    .c-dropdown__list {
      border-radius: 3px;
      box-shadow: 0 0 20px rgba(190, 190, 190, 0.2);
      display: flex;
      flex-direction: column;
      white-space: nowrap;

      &.c-dropdown__list--dark {
        // overrides for dark list
        background-color: $d1-dark-gray;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
        color: $light-white;
        padding: 5px 0;

        .c-dropdown__item {
          // showrunner <button> overrides
          background-color: inherit;
          border: 0;
          border-radius: 0;
          color: $light-white;
          font-family: $base-font-family;
          font-size: $s1-font-size;
          height: auto;
          margin-bottom: auto; // skeleton <li> override
          padding: 4px 8px;
          text-align: left;
          text-transform: none;
          // unique styles
          width: 100%;

          &:hover {
            background-color: $base-dark-gray;
            color: $light-white;
            cursor: pointer;
          }
        }
      }

      &.c-dropdown__list--action {
        // denotes a list of actions, typically containing <button>s of anchor tags
        background-color: $light-white;
        border: 1px solid $base-light-gray;
        border-radius: 3px;
        padding: 5px 0;
      }

      .c-dropdown__item.c-dropdown__item--selected,
      .c-dropdown__item.c-dropdown__item--selected:hover {
        background-color: $d1-purple;
        color: $light-white;
      }

      .c-dropdown__item.c-dropdown__item--red {
        color: $error-color;

        &:hover {
          color: $error-color;
        }
      }
    }
  }

  .o-button {
    .o-button__label {
      display: inline;
    }
  }

  .c-dropdown__list {
    display: none;
    list-style-type: none;
    position: absolute;
    top: 100%;
    z-index: map-get($zindex, 'dropdown');
  }

  .c-dropdown__divider {
    border-bottom: 1px solid $d1-light-gray;
    margin: 4px 0;
  }
}

// dropdown lists in which some list items have associated icons and some don't
.c-dropdown .c-dropdown__list--some-icons {
  .c-dropdown__item.c-dropdown__item--no-icon {
    padding: 4px 8px 4px 20px;
  }

  .c-dropdown__item.c-dropdown__item--icon {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    padding-left: 6px;
  }

  .c-dropdown__icon.c-dropdown__icon--small {
    color: $base-mid-gray;
    display: inline;
    font-size: $s3-font-size;
    margin-right: 4px;
    width: unset;
  }
}

// toolbar dropdowns

.c-dropdown.is-open {
  .c-dropdown__list.c-dropdown__list--action.c-dropdown__list--table {
    border: 1px solid $d1-light-gray;
    margin-top: 1px;
    padding: 4px 0;
    width: 100%;
  }

  .c-dropdown__list.c-dropdown__list--action {
    padding: 8px 0 4px;
  }

  .c-dropdown__item {
    padding: 6px 12px;

    &.c-dropdown__item--selected,
    &.c-dropdown__item--selected:hover {
      background-color: $l3-light-gray;
      color: $d2-dark-gray;
    }
  }

  .c-dropdown__inline-link {
    display: inline;
    font-size: inherit;
    padding: 0;
    text-align: left;
  }

  .c-dropdown__meta {
    color: #74757b;
    font-size: 14px;
    margin-top: -2px;
    padding-top: 0;
    white-space: normal; // allows text to wrap

    &:hover {
      background-color: unset;
      cursor: default;
    }
  }

  .c-dropdown__divider.c-dropdown__divider--spacey {
    margin: 8px 0;
  }
}

// classic dropdown

.o-dropdown {
  display: inline;
  position: relative;
  width: auto;

  .o-dropdown__list {
    display: none;
  }

  .o-button {
    .o-button__label {
      display: inline;
    }
  }

  &.is-open {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .o-dropdown__list {
      align-items: flex-start;
      background-color: $light-white;
      border: 1px solid $d1-light-gray;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      list-style-type: none;
      margin-top: 2px;
      min-width: 150px;
      padding: 4px 0;
      position: absolute;
      top: 100%;
      width: auto;
      z-index: map-get($zindex, 'dropdown');

      // BEGIN DRAFTS VERSION
      &.o-dropdown__list--drafts {
        box-shadow: 0 0 4px rgba(190, 190, 190, 0.3);
        left: 0;
        margin-left: 5px;
        min-width: 210px;

        .o-dropdownlist__item {
          padding: 6px 6px 6px 14px;

          &:hover {
            background-color: $l3-light-gray;
            color: $d1-blue-gray;
          }

          // because each fontawesome icon is a different width
          // we need specific margins for each list item
          // (our eventual switch to fontawesome 5 will fix this)
          &:nth-of-type(1) {
            .icon {
              margin-right: 10px;
            }
          }

          &:nth-of-type(2) {
            .icon {
              margin-right: 7px;
            }
          }

          &:nth-of-type(3) {
            .icon {
              margin-right: 11px;
            }
          }

          .o-dropdownitem__text {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
          }
        }
      }
      // END DRAFTS VERSION

      &.o-dropdown__list {
        border: 1px solid $d1-light-gray;
        border-radius: 3px;
        box-shadow: 0 0 4px rgba(190, 190, 190, 0.3);

        .o-dropdownlist__item {
          align-items: center;
          border: 0;
          display: flex;

          &:hover {
            background-color: $l3-light-gray;

            a {
              color: $d2-dark-gray;
            }
          }

          &.o-dropdownlist__item--red {
            color: $error-color;

            &:hover {
              color: $error-color;
            }
          }

          &.o-dropdownlist__item--disabled,
          &.o-dropdownlist__item--disabled.o-dropdownlist__item--red {
            color: $d3-light-gray;

            &:hover {
              background-color: $light-white;
              color: $d3-light-gray;
              cursor: default;
            }

            .o-icon {
              // disabled color needs to override icon color for disabled list items
              color: $d3-light-gray;
            }
          }

          &.is-unclickable {
            font-size: $s1-font-size;
            padding: 5px 8px;

            &:hover {
              background-color: $light-white;
              color: $d1-mid-gray;
              cursor: default;
            }
          }

          .o-dropitem__icon {
            font-size: 14px;
            width: 24px; // compensates for different FontAwesome widths
          }

          i {
            margin-right: 5px;
          }

          &.o-dropdownlist__item--dashboard {
            padding: 5px 8px;
            white-space: nowrap;
          }

          // TODO: remove bare 'a' selector once the new class is in use in the app
          .o-dropdownlist__item--link,
          a {
            color: $d2-dark-gray;
            display: block;
            padding: 5px 8px;
            width: 100%;

            &:hover {
              text-decoration: none;
            }

            i {
              margin-right: 8px;
            }
          }
        }
      }

      &.o-dropdown__list--dark {
        background-color: $d1-dark-gray;
        border: 1px solid $d1-dark-gray;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
        border-radius: 3px;
        font-family: $base-font-family;

        .o-dropdownlist__item {
          border-bottom: 0;

          &:hover {
            background-color: $l1-dark-gray;
            color: $light-white;
          }

          a {
            &:hover {
              color: $light-white;
            }
          }

          &.is-unclickable {
            font-style: italic;
            opacity: 0.6;

            &:hover {
              background-color: $d1-blue-gray;
              cursor: default;
            }
          }
        }
      }

      .o-dropdownlist__item {
        border-bottom: 1px solid $d1-light-gray;
        width: 100%;

        &.is-current-selection {
          background-color: $l3-light-gray;
          color: $d1-mid-gray;

          &:hover {
            color: $d1-mid-gray;
          }
        }

        &.is-delete {
          color: $error-color;

          &:hover {
            color: $error-dark-color;
          }
        }

        &:last-of-type {
          border-bottom: 0;
        }

        .o-dropdownlist__item {
          color: $d2-dark-gray;
          width: 100%;

          &:hover {
            text-decoration: none;
          }
        }
      }

      .o-dropdownlist__sublist {
        // styles for any ul that lives inside of the first dropdown ul
        // e.g. the list of recent drafts inside the drafts menu
        background-color: $l3-light-gray;
        box-shadow: inset 0 0 2px rgba(190, 190, 190, 0.5);
        padding: 5px 0;
        width: 100%;

        .o-dropdownsublist__item {
          border-bottom: 0;
          font-size: $s1-font-size;
          padding: 5px 10px;
          width: 100%;

          &:hover {
            background-color: $light-white;
            color: $d1-blue-gray;
          }

          &.o-dropdownsublist__item--drafts {
            // styles for the drafts sublist's highly specific layout
            // e.g. user avatars, draft titles, and bylines
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            .o-dropsubitem__pic img {
              height: auto;
              margin-right: 8px;
              width: 20px;
            }

            .o-dropsubitem__text {
              .o-dropsubtext__byline {
                color: $d1-mid-gray;
                font-size: $s2-font-size;
              }
            }

            &:last-of-type {
              // targets the "x more drafts..." link at the end of the recent drafts list
              padding: 5px 36px;

              &:hover {
                // makes "x more drafts..." look an behave like a regular link
                background-color: $l3-light-gray;
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
}

// ellipsis dropdown

.o-dropdown__button.o-dropdown__button--ellipsis {
  align-content: center;
  background-color: $light-white;
  border: 0;
  border-radius: 3px;
  display: flex;
  font-size: 20px;
  padding: 10px; //override semantic button defaults

  &:hover {
    background-color: $l3-light-gray;

    .icon {
      color: $d2-dark-gray;
    }
  }

  &.o-dropdown__button--darkbg {
    &:hover {
      background-color: transparent;

      .icon {
        color: $light-white;
      }
    }
  }
}
/* stylelint-enable */
