
          @use '@scss/all' as wallaby;
        
.sideBySideDiff {
  height: 100%;
  max-width: 17in;
  overflow: auto;
  padding: 15px;
  width: calc(100%);
}

.leftSelect .sideBySideDiff_rowSide.right {
  user-select: none;
}

.rightSelect .sideBySideDiff_rowSide.left {
  user-select: none;
}

.sideBySideDiff_row {
  display: flex;
}

.sideBySideDiff_rowSide {
  background-color: wallaby.$light-white;
  flex: 1;
  font-family: 'Courier Prime', courier, monospace;
  line-height: 1.3;
  padding: 10px 20px;
  word-break: break-word;

  &.left {
    margin-right: 10px;

    .changed {
      background-color: wallaby.$deletion-diff-light;
      border-bottom: 2px wallaby.$deletion-diff-dark dashed;
    }
  }

  &.right {
    margin-left: 10px;

    .changed {
      background-color: wallaby.$addition-diff-light;
      border-bottom: 2px wallaby.$addition-diff-dark solid;
    }
  }

  &.omitted {
    background-color: transparent;
    color: wallaby.$theme-gray-6;
    font-family: 'Lato', sans-serif;
    padding: 15px 0;
    text-align: center;
  }

  &.title {
    flex-wrap: nowrap;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.lineBreak {
  padding: 0 20px;
}

.sideBySideDiff.monochrome {
  .sideBySideDiff_rowSide {
    &.left {
      .changed {
        background-color: transparent;
        border-bottom: 0;
        text-decoration: line-through;
      }
    }

    &.right {
      .changed {
        background-color: transparent;
        border-bottom: unset;
        text-decoration-color: #000;
        text-decoration-line: underline;
        text-decoration-style: double;
        text-decoration-thickness: 1px;
        text-underline-offset: 6px;
      }
    }
  }
}

@media screen {
  .sideBySideDiff_rowSide.title {
    align-items: center;
    background-color: wallaby.$theme-gray-0;
    border-bottom: 1px wallaby.$theme-gray-2 solid;
    display: flex;
    height: 40px;
    padding: 0 10px;
  }

  .titleText {
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .subtitleText {
    color: wallaby.$theme-gray-6;
    flex-shrink: 0;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
  }

  .sleneName {
    background-color: wallaby.$theme-violet-0;
    border-radius: 3px;
    color: wallaby.$theme-violet-5;
    font-family: 'Lato', sans-serif;
    font-size: 15px;
    margin-right: 5px;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
  }
}

@media print {
  @page {
    margin: 0.25in;
    margin-top: 0;
  }

  html,
  body {
    background-color: wallaby.$light-white !important;
  }

  body {
    display: block;
    overflow: visible !important;
    position: relative;
    print-color-adjust: exact !important;
  }

  .sideBySideDiff {
    margin-top: 0;

    .sideBySideDiff_row {
      break-inside: avoid;
      width: 100%;
    }

    .sideBySideDiff_rowSide {
      background-color: transparent;
    }

    // line down the center except for the title row
    .sideBySideDiff_row:not(.title) {
      background: linear-gradient(wallaby.$theme-gray-3, wallaby.$theme-gray-3)
        no-repeat center/2px 100%;
    }

    // The title row becomes the header on every page and we add the file name as
    // a pseudo-element
    .sideBySideDiff_row.title {
      align-items: flex-end;
      left: 0;
      position: fixed;
      text-align: center;
      top: 0;
      width: 100%;

      .sideBySideDiff_rowSide {
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
      }

      &::before {
        content: var(--file-name);
        left: 0.25in;
        position: absolute;
        top: 0.25in;
      }
    }
  }

  .sleneName {
    border: 1px #000 solid;
    margin: auto;
    margin-bottom: 5px;
    max-width: 100%;
    overflow: hidden;
    padding: 2px 5px;
    text-overflow: ellipsis;
    width: fit-content;
  }
}
