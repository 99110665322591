// element toolbar

.c-eltoolbar {
  background-color: $light-white;
  border: 1px solid $d1-light-gray;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(190, 190, 190, 0.3);
  display: inline-flex;
  flex-direction: column;
  z-index: map-get($zindex, 'editor-dropdown');

  .c-eltoolbar__list {
    align-items: flex-start;
    border-bottom: 1px solid $d1-light-gray;
    display: flex;
    flex-direction: column;
    padding: 5px 0;

    &:last-of-type {
      border-bottom: 0;
    }

    .c-eltoolbarlist__button {
      background-color: $light-white;
      border: 0;
      border-radius: 0;
      font-family: $base-font-family;
      font-size: $base-font-size;
      height: auto;
      letter-spacing: normal;
      padding: 8px 15px 8px 10px;
      text-transform: none;
      width: 250px;

      &:hover {
        background-color: $l3-light-gray;
      }

      &.is-selected {
        background-color: $d1-purple;

        /* stylelint-disable */
        // over-nesting to override already overly-nested default styles
        // TODO: unnest
        .c-eltoolbutton__text .c-eltoolbuttontext__label {
          color: $light-white;
        }
        /* stylelint-enable */
      }

      &.c-eltoolbarlist__button--tip {
        &:hover {
          background-color: $light-white;
          cursor: default;
        }
      }
    }
  }

  .c-eltoolbutton__text {
    display: flex;
    justify-content: space-between;
  }

  .c-eltoolbuttontext__label {
    color: $base-blue-gray;
    display: flex;
    flex-direction: row;

    &.c-eltoolbuttontext__label--tip {
      color: $d1-mid-gray;
    }
  }

  .c-eltoolbuttonlabel__elementtext {
    margin-left: 10px;
  }

  .c-eltoollabel__icon {
    width: 30px;

    i {
      font-family: 'icomoon';
      font-size: 16px;
      font-style: normal;
    }
  }

  .c-eltoolbuttontext__shortcut {
    color: $d3-light-gray;
  }
}
