
          @use '@scss/all' as wallaby;
        
.dropdown {
  overflow: auto;
}

.item {
  font-size: var(--mantine-font-size-med);
  padding: 6px;

  &:disabled {
    color: var(--mantine-color-gray-6);
  }

  // a hair darker
  &[data-hovered='true'] {
    background-color: var(--mantine-color-gray-2);
  }
}

.itemSection {
  font-size: var(--mantine-font-size-sm);
}

.label {
  font-weight: 700;
  padding: 6px;
  text-transform: uppercase;
}
