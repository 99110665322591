
          @use '@scss/all' as wallaby;
        
.open,
.limited,
.private {
  font-size: 14px;
}

.open {
  i {
    color: wallaby.$script-open-color;
  }
}

.limited {
  i {
    color: wallaby.$script-limited-color;
  }
}

.private {
  i {
    color: wallaby.$script-private-color;
  }
}
