
          @use '@scss/all' as wallaby;
        
.body {
  align-items: 'center';
}

.description {
  color: var(--mantine-color-gray-6);
  margin-top: 0;
}

.error {
  margin-top: 2px;
}

.label {
  font-size: 16px;
}

[data-size='sm'] {
  .description {
    font-size: 16px;
  }

  .error {
    font-size: 14px;
  }
}

.input:hover {
  cursor: 'default';
  outline: 3px solid var(--mantine-color-gray-2);
}

.input:checked {
  background-color: var(--mantine-color-violet-6);
}
