// PICKER LIST
// as seen in compare view

.c-picklist {
  border: 1px solid $d1-light-gray;
  border-radius: 3px;
  max-height: 250px;
  overflow-y: auto;
  padding: 5px;

  .c-picklist__item {
    border-radius: 3px;
    padding: 5px;
    width: 100%;

    &:hover {
      background-color: $l3-light-gray;
      cursor: pointer;
    }

    .c-pickitem__link {
      // accounts for <a>s, <button>s, and plain text
      border: 0;
      color: $d2-dark-gray;
      display: flex;
      flex-direction: column;
      font-family: $base-font-family;
      font-size: $base-font-size;
      font-weight: inherit;
      height: auto;
      line-height: normal;
      margin-bottom: auto;
      padding: 0;
      text-align: left;
      text-transform: none;
      white-space: normal;
      width: 100%;
    }

    .c-pickitem__link,
    .c-pickitem__link--selected {
      &:hover {
        text-decoration: none;
      }
    }

    &.c-picklist__item--selected {
      background-color: $d1-purple;
      color: $light-white;

      .c-pickitem__link {
        color: $light-white;
      }

      .c-pickitem__subitem.has-light-text,
      .is-medlight {
        color: $light-white;
        opacity: 0.6;
      }
    }

    .c-pickitem__subitem:nth-of-type(2) {
      margin-left: 5px;
    }
  }
}
