
          @use '@scss/all' as wallaby;
        
.search_results {
  flex: 1;
  overflow-y: auto;
  padding: 10px 4px;
}

.search_nav:hover {
  text-decoration: none;
}

.search_card {
  border-radius: 3px;
  font-size: 15px;
  padding: 10px;

  &:hover {
    background-color: wallaby.$theme-gray-1;
    cursor: pointer;
  }
}

.search_card___selected {
  background-color: wallaby.$theme-violet-0;

  &:hover {
    background-color: wallaby.$theme-violet-0;
  }
}

.search_crumb {
  color: wallaby.$theme-gray-6;
}

.search_snippet {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  overflow: hidden;
}
