
          @use '@scss/all' as wallaby;
        
.documentOutline {
  counter-reset: bracketnum;
  counter-reset: scenenum;
  counter-reset: slugnum;

  display: flex;
  flex-direction: column;
  font-family: wallaby.$script-font-family;
  font-size: wallaby.$s1-font-size;
  height: 100%;
  justify-content: space-between;
  left: 0;
  overflow: hidden;
  overscroll-behavior: contain; /* safari ignores */
  position: relative;
  top: 0;
  z-index: map-get(wallaby.$zindex, 'editor-menu');

  // documentOutlineNumbering

  [data-nav-link='bracket'] {
    counter-increment: bracketnum;
  }

  [data-nav-link='sceneHeading'] {
    counter-increment: scenenum;
  }

  [data-nav-link='slug'] {
    counter-increment: slugnum;
  }

  &:global(.auto-bracketnums
      [data-nav-link='bracket']
      .c-snav__element::before) {
    content: counter(bracketnum) '. ';
  }

  &:global(.auto-scenenums
      [data-nav-link='sceneHeading']
      .c-snav__element::before) {
    content: counter(scenenum) '. ';
  }

  &:global(.auto-slugnums [data-nav-link='slug'] .c-snav__element::before) {
    content: counter(slugnum) '. ';
  }
}

.documentOutline_scroll {
  flex: 1;
  overflow-y: auto;
  padding: 10px 0;
}

.documentOutline_footer {
  border-top: 1px solid wallaby.$theme-gray-3;
  font-family: wallaby.$base-font-family;
  padding: 8px 5px;
  white-space: nowrap;
}
