
          @use '@scss/all' as wallaby;
        
/* stylelint-disable selector-max-compound-selectors */
/* stylelint-disable selector-no-qualifying-type */
$left-gutter: 50px;
$right-gutter: 77px;

.pagelessScroller {
  display: flex;
  height: 100%;
  overflow-y: auto;
  position: relative;
}

.pagelessContent {
  height: fit-content;
  overflow: hidden;
  position: absolute;
  transform: scale(var(--zoom-level));
  transform-origin: top left;
  width: calc(100% / var(--zoom-level));
}

.pagelessContent.noWrap {
  width: unset;

  :global(.o-block-element) {
    white-space: pre;
  }
}

.pagelessContent :global {
  .ProseMirror {
    // put background on .ProseMirror (the entire document) instead of per-page
    background-color: wallaby.$light-white;
    min-height: calc(var(--prose-editor-height) / var(--zoom-level));
    min-width: calc(var(--prose-editor-width) / var(--zoom-level));
    // pad the top and botttom of the entire script. The bottom padding is big enough
    // to fit a multi-line ink compile error tooltip
    padding: 30px 0 90px;
  }

  .pagebreak-hide-label {
    display: none;
  }

  .c-editor,
  .c-editor-banner {
    min-width: 250px;
    width: 100%;
  }

  .c-commentcount {
    // this bg makes the comment button obscure exclude from timing markings
    // if present
    background-color: wallaby.$light-white;
    pointer-events: all;
  }

  .c-commentscontainer {
    left: unset;
    pointer-events: all;
    right: 15px;
  }

  .c-commentscontainer .c-comment__close {
    color: wallaby.$theme-gray-4;
    margin-top: 4px;
    right: 8px;

    &:hover {
      color: wallaby.$light-white;
    }
  }

  // so the close button doesn't obscure the send button
  .c-comment.c-comment--new {
    padding-right: 24px;
  }

  .c-editor__gutterright {
    margin-left: -$right-gutter;
    pointer-events: none;
    width: $right-gutter;
  }

  .newcomment {
    font-size: 14px;
    height: 30px;
    left: 8px;
    pointer-events: all;
    width: 30px;
  }

  .o-page {
    background: transparent;
    min-height: unset;
    padding-bottom: 0;
    padding-left: $left-gutter;
    padding-right: $right-gutter;
    padding-top: 0;
    width: 100%;
  }

  // remove gaps between pages
  .o-page + .o-page {
    margin-top: unset;
  }

  // hide locked page icon
  .ProseMirror .o-page[data-locked='true']::after {
    display: none;
  }

  // hide all page numbers and margin decorations
  [data-margins],
  .e-page-number {
    display: none;
  }

  .o-block-element {
    position: relative;
    width: unset;
  }

  .o-block-element.is-untimed::before {
    left: calc(100% + 2px) !important;
  }

  // make the remote avatars into smaller colored dots
  .o-dot {
    border: 10px transparent solid;
    height: 24px;
    left: 25px;
    margin-top: 10px;
    width: 24px;

    img {
      height: 20px;
      width: 20px;
    }

    &.o-hovertooltip[data-tooltip]::after {
      left: 12px;
      top: -8px;
    }
  }

  .o-elementicon {
    display: none;
  }

  input[type='text'].e-element-number-input {
    right: unset;
  }
}
