// legacy forms

.parenthetical {
  /* for when form labels need unbolded supplemental text, e.g. "optional" */
  font-weight: 400;
  line-height: 1;
  margin: auto auto 4px 0;
  /* adds a bit of needed space between it  and label beneath it */
}

/*
the following styles break a lint rule (`selector-no-qualifying-type`), but since they'd be difficult and time-consuming to fix, we've made the conscious decision to leave them be
*/

/* stylelint-disable */
input[type='text']:not([class*='mantine-']),
select {
  border: 1px solid #dbdbdb;
  border-radius: 3px;
}

input[type='text']:not([class*='mantine-']):focus,
select:not([class*='mantine-']):focus {
  border: 1px solid #8252c6;
  box-shadow: 0 0 2px #b19ccf;
}
/* stylelint-enable */

/* stylelint-disable-next-line selector-class-pattern */
input:focus:not([class*='mantine-']) {
  border: 1px solid #f00;
  box-shadow: 0 0 2px #b19ccf;
}

input:invalid:not(:placeholder-shown):not([class*='mantine-']) {
  border: 1px solid #f00;
  box-shadow: unset;
}

/* stylelint-disable */
// ignoring linting because it breaks the `selector-max-id` rule
// letting it slide because it's the only time we have or will ever use IDs for styling
#error {
  border: 1px solid #f00;
  box-shadow: 0 0 2px #f00;
}
/* stylelint-enable */

//form, .form {
/* container for all form elements */
//width: 100%;
//}

.form-field {
  margin-bottom: 10px;
  max-width: 300px;
}

.inline-form-field {
  /* must be below .form-field */
  margin-bottom: 0;
}

.form-outer {
  align-items: center;
  display: flex;
  flex-direction: column;
  /* container that includes the headings above
    and links below the form itself*/
  margin: 0 auto;
}

.form-inner {
  align-items: center;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(186, 186, 185, 0.5);
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 20px 20px 10px;
  width: 340px;
}

.centered-container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

/*
the following styles break a lint rule (`selector-no-qualifying-type`), but since they'd be difficult and time-consuming to fix, we've made the conscious decision to leave them be
*/

/* stylelint-disable */
input[type='email'],
input[type='password'] {
  width: 100%;
}

input[type='email']:focus,
input[type='password']:focus {
  border: 1px solid #8252c6;
  box-shadow: 0 0 2px #b19ccf;
}
/* stylelint-enable */

.left-form-line {
  /* for left-aligned non-input form text */
  font-size: 14px;
  margin: 5px auto;
  width: 100%;
}

.left-form-line i {
  margin-right: 3px;
  /* adds spacing to the .left-form-line icons */
}

.sign-up-line,
.form-footnote {
  /* .sign-up-line can be replaced by .form-footnote everywhere it occurs, I just need to do thedang thing */
  color: #9798a6;
  font-size: 16px;
  margin: 0 auto 20px;
}

.no-account-line {
  margin-right: 5px;
}
