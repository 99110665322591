
          @use '@scss/all' as wallaby;
        
.c-format-editor {
  display: flex;
  inset: 0;
  max-height: 100%;
  overflow: hidden;
  padding-top: 20px;

  > * {
    flex: 1;
  }

  .c-format-editor__error {
    color: wallaby.$error-dark-color;
  }

  .c-format-editor__tools {
    display: flex;
    flex-direction: column;
    padding-left: 20px;

    .button-bar {
      display: flex;
    }

    .guid-entry {
      width: 380px;
    }

    textarea {
      flex: 1;
      font-family: wallaby.$script-font-family;
      margin-bottom: 30px;
      width: 100%;
    }

    .format-modifier-controls {
      border-top: 1px #000 dashed;
      margin-top: 10px;
      padding-top: 10px;
    }
  }
}
