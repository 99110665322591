
          @use '@scss/all' as wallaby;
        
// breadcrumb
.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  gap: 5px 10px;
  margin-bottom: 10px;
  min-height: 19px;
  word-break: break-all;
}

.breadcrumb_link,
.breadcrumb_link:visited {
  color: wallaby.$l1-mid-gray;
  font-weight: bold;

  &:hover {
    color: wallaby.$d2-dark-gray;
    cursor: pointer;
    text-decoration: none;
  }
}

.breadcrumb_slash {
  color: wallaby.$d3-light-gray;
}
