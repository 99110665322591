//
// UTILITIES AND MODIFIERS //
//

// view states

.is-invisible {
  display: none;
}

// layout and display

.is-mini-wrapper {
  // for single-column screens, like sign in
  max-width: 600px;
}

.has-space-between {
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.has-centered-items {
  align-items: center;
  display: flex;
}

// background colors

.has-dark-background {
  background-color: $d1-blue-gray;
}

.has-white-background {
  background-color: $light-white;
}

.has-lightgray-background {
  background-color: $l3-light-gray;
}

// text colors and weights

.has-white-text {
  color: $light-white;
}

.is-light {
  color: $d1-mid-gray;
}

.is-medlight {
  color: $l2-dark-gray;
}

.is-green,
.icon.is-green {
  color: $success-color;
}

.is-caps-gray {
  color: $d1-mid-gray;
  font-family: $heading-font-family;
  font-size: $s2-font-size;
  font-weight: $heading-font-weight;
  text-transform: uppercase;
}

.is-bold {
  font-weight: $heading-font-weight;
}

.is-italic {
  font-style: italic;
}

.is-underlined {
  text-decoration: underline;
}

// text size

.is-small {
  font-size: $s1-font-size;
}
