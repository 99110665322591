
          @use '@scss/all' as wallaby;
        
.hyperlinkPopover_target {
  height: 1em;
  position: absolute;
}

// we're delegating all styling of the dropdown
// to HyperlinkInner (so we can storybook it)
.hyperlinkPopover_dropdown {
  border: 0;
  padding: 0;
}
