
          @use '@scss/all' as wallaby;
        
.avatar_wrapper {
  border-radius: 50%;
  flex-wrap: nowrap;
  height: 32px;
  position: relative;
  width: 32px;

  &.avatar_wrapper___profile {
    height: 100px;
    margin: 5px;
    width: 100px;
  }

  .avatar_image {
    border: 2px wallaby.$light-white solid;
    border-radius: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;

    &.avatar_image___profile {
      border: 0;
    }
  }

  .dot {
    background-color: wallaby.$warning-color;
    border: 2px wallaby.$light-white solid;
    border-radius: 50%;
    bottom: -2px;
    height: 12px;
    position: absolute;
    right: -2px;
    width: 12px;
  }

  .online_dot {
    background-color: wallaby.$success-color;
  }
}
