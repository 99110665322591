// legacy links

.light-purple-link {
  color: #b89ade;
}

.light-purple-link:hover {
  color: #fff;
}

.purple-link {
  color: #8252c6;
}

.purple-link:hover {
  color: #4d2e7a;
}
