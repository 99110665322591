// THE EDITOR, ITSELF //

// universal: the editor itself is 8.5 inches and uses a flex layout to position
// the editor and its gutters.
.c-editor {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 8.5in;
}

.c-editor__gutterleft {
  margin-right: -1.5in;
  position: relative;
  width: 1.5in;

  // default positioning for element icon & toolbar
  // when they're in the left gutter
  .c-eltoolbar {
    position: absolute;
    right: 5px;
  }

  .o-elementicon {
    position: absolute;
    right: 5px;
    top: 245px;
  }
}

.c-editor__overlay {
  bottom: 0;
  left: 1.5in;
  pointer-events: none;
  position: absolute;
  right: 1in;
  top: 0;
  z-index: map-get($zindex, 'editor-ui');

  * {
    pointer-events: auto;
  }
}

.c-editor__gutterright {
  margin-left: -1in;
  position: relative;
  width: 1in;
}
