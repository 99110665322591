
          @use '@scss/all' as wallaby;
        
.userSettings_page {
  min-width: 500px;
  padding: 0 30px;
}

.userSettings_group {
  align-items: flex-start;
  display: grid;
  gap: 20px;
  grid-template-columns: 2fr 1fr;
  width: 100%;
}
