
          @use '@scss/all' as wallaby;
        
.search_filterTag {
  align-items: center;
  background-color: wallaby.$theme-violet-0;
  border: 1px solid wallaby.$theme-violet-0;
  border-radius: 3px;
  display: inline-flex;
  padding: 2px;
}

.search_filterTagText {
  border-radius: 3px;
  cursor: pointer;
  padding: 2px 4px;

  &:hover {
    background-color: wallaby.$theme-violet-1;
  }
}

.search_filterTagClose {
  // mantine actionicon overrides
  height: unset;
  min-height: unset;
  min-width: unset;
  padding: 2px 4px;
  width: unset;

  &:hover {
    background-color: wallaby.$theme-violet-1;
  }
}
