
          @use '@scss/all' as wallaby;
        
.membertablecontainer {
  max-height: 40vh;
  overflow: auto;
  padding-right: 0;
}

.membertable {
  background-color: #fff;
  border: 1px solid wallaby.$theme-gray-3;
}
