
          @use '@scss/all' as wallaby;
        
.level1 {
  background-color: #9aeaa5 !important;
}

.level2 {
  background-color: #cfeaf2 !important;
}

.level3 {
  background-color: #f4f4f4 !important;
}

// show page breaks as black dashed line in on-screen print preview
@media screen {
  :global(.ag-layout-print) .pageBreakRow {
    &::after {
      border-top: 3px wallaby.$theme-gray-8 dashed;
      content: '';
      left: 0;
      position: absolute;
      top: -1.5px;
      width: 100%;
    }
  }
}

// make page breaks into actual page breaks when printing
@media print {
  :global(.ag-layout-print) .pageBreakRow {
    page-break-before: always;
  }
}
