// buttons

button:hover {
  // preserves expected cursor behavior for buttons that aren't styled like buttons
  // e.g. dropdown items, comment indicators
  cursor: pointer;
}

.o-button {
  align-items: center;
  background-color: $light-white;
  border: 1px solid $d1-light-gray;
  border-radius: 3px;
  display: flex;
  font-family: $base-font-family;
  font-size: $s1-font-size;
  font-weight: 500;
  padding: 6px 12px;
  white-space: nowrap;

  &:hover,
  &:focus {
    border: 1px solid $d3-purple;
    color: $d3-purple;
    cursor: pointer; // for non-`button`s styled as buttons
    text-decoration: none; // for links styled as buttons
  }

  &.is-small {
    border-radius: 2px;
    font-size: $s1-font-size;
    padding: 2px 6px;
  }

  // primary

  &.o-button--primary {
    background-color: $d1-purple;
    border: 1px solid darken($d1-purple, 5%);
    color: $light-white;

    &:hover,
    &:focus {
      background-color: $d4-purple;
      border: 1px solid $d4-purple;
    }
  }

  // secondary

  &.o-button--secondary {
    background-color: $light-white;
    border: 1px solid $d1-light-gray;
    color: $d2-dark-gray;
    font-family: $base-font-family;
    font-weight: 500;
    height: auto;
    letter-spacing: normal;
    text-transform: none;

    &:hover {
      background-color: $l3-light-gray;
    }
  }

  // button with an icon to the left of the label text
  // (as opposed to a dropdown or close button)

  &.o-button--iconic {
    &:hover {
      .o-button__icon--dark {
        color: $d2-dark-gray;
      }
    }
  }

  .o-button__icon {
    font-size: $s3-font-size;
    margin-right: 2px;
  }

  .o-button__icon--dark {
    color: $d2-dark-gray;
  }

  // button disguised as link

  &.o-button--link {
    background-color: transparent;
    border: 0;
    border-radius: 3px;
    color: $d3-purple;
    font-family: $base-font-family;
    font-size: $base-font-size;
    text-transform: none;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &.o-button--errorlink {
      color: $error-color;
    }
  }

  // borderless plain text button that blends in with a white background
  // would work well in a toolbar ;)

  &.o-button--discreet {
    align-items: baseline;
    border: 0;
    color: $d2-dark-gray; // override for when buttons are secretly links
    display: flex;
    font-family: $base-font-family;
    font-size: $base-font-size;
    padding: 4px 8px;
    text-transform: none;

    &:hover {
      background-color: $l3-light-gray;
      color: $d2-dark-gray;

      .o-button__icon {
        color: $d2-dark-gray;
      }
    }

    &.o-button--disabled {
      background-color: transparent;
      border: 0;
      color: $d3-light-gray;

      &:hover {
        background-color: transparent;
        border: 0;
        color: $d3-light-gray;
        cursor: default;
      }
    }

    .o-button__icon {
      color: $d2-dark-gray;
      font-size: $s2-font-size;
    }
  }

  // useful for icon-only buttons

  &.o-button--circle {
    border-radius: 50%;
  }

  // useful for dark backgrounds,
  // particularly for iconic buttons

  &.o-button--inverted {
    background-color: transparent;
    border: 0;
    color: $d3-light-gray;

    &:hover {
      color: $light-white;
    }
  }

  // disabled

  &.o-button--disabled {
    background-color: $d3-light-gray;
    border: 1px solid $d3-light-gray;
    border-radius: 3px;
    color: $light-white;

    &:hover {
      background-color: $d3-light-gray;
      border: 1px solid $d3-light-gray;
      cursor: default;
    }
  }

  &.o-button--image {
    background-color: transparent;
    border: 0;

    &:hover {
      border: 0;
    }
  }

  // danger or error

  &.o-button--danger {
    border: 1px solid $error-color;
    color: $error-color;

    &:hover,
    &:focus {
      background-color: $error-light-color;
    }
  }

  // x

  &.o-button--close {
    background-color: transparent;
    border: 0;
    color: $base-mid-gray;
    font-size: $t4-font-size;

    &:hover {
      color: $d2-dark-gray;
    }
  }

  // button that needs to appear inline with other text
  // (usually paired with .o-button--link)

  &.o-button--inline {
    padding: 0 3px;
  }

  // sidebar / modal nav buttons
  // i.e. an `x` with a light-gray background,
  // can be accompanied by a hover tooltip

  &.o-button--back,
  &.o-button--close {
    // unique styles
    background-color: $l3-light-gray;
    // showrunner <button> overrides
    border: 0;
    color: $base-mid-gray;
    font-size: $base-font-size;
    height: auto;
    padding: 0;
    padding: 4px 8px;

    &:hover {
      // unique styles
      background-color: $d1-light-gray;
      // `<a>` override
      border: 0;
      // skeleton *and* showrunner `<button>` override
      color: $d2-dark-gray;
    }

    &.o-hovertooltip[data-tooltip]::after {
      // associated tooltip positioning
      top: 40px;
    }
  }

  &.o-button--back {
    &.o-hovertooltip[data-tooltip]::after {
      // associated tooltip positioning
      left: 8px;
    }
  }

  &.o-button--close {
    &.o-hovertooltip[data-tooltip]::after {
      // associated tooltip positioning
      right: 8px;
    }
  }

  &.o-button--input {
    // compensate for browser input defaults
    margin-top: 0;
  }
}
