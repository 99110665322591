
          @use '@scss/all' as wallaby;
        
.inkPlayer {
  background-color: wallaby.$light-white;
  height: 100%;
  overflow: hidden;
}

// (hack) in lieu of setting padding on the toolbar itself
.inkPlayer_button {
  margin-bottom: 4px;
  margin-top: 4px;
}

.storyLine {
  background-color: wallaby.$theme-gray-1;
  border-bottom: 2px solid wallaby.$light-white;
  padding: 20px;
}

.choices {
  padding-bottom: 40px;
  padding-top: 10px;
}

.choice {
  padding: 10px;
  text-align: center;
}
