
          @use '@scss/all' as wallaby;
        
.membertablescroll {
  height: 70vh;
}

.membertable {
  // instance-specific (probably)
  background-color: #fff;
  // could be THEMEd
  border: 1px solid wallaby.$theme-gray-3;
}

.membertable_th {
  padding: 5px 10px;
}
