
          @use '@scss/all' as wallaby;
        
.promptermodal_scroll {
  border: 1px solid wallaby.$theme-gray-4;
  border-radius: 0 0 3px 3px;
  height: 25vh;
  padding: 0 10px;
}

.promptermodal_card {
  border: 1px solid wallaby.$theme-gray-4;
  border-radius: 3px;
  padding: 10px;
}

.promptermodal_checkgroup {
  margin-bottom: 15px;

  &:first-of-type {
    margin-top: 10px;
  }
}

.promptermodal_checktitlegroup {
  align-items: flex-start;
  flex-wrap: nowrap;
  width: 100%;
}

.promptermodal_checktitlestack {
  width: 90%;
}

.promptermodal_text {
  line-height: 1.2; // instance-specific
}

.promptermodal_badge {
  background-color: wallaby.$theme-orange-0;
  color: wallaby.$theme-orange-9;
  margin-top: 3px; // to appear flush with the baseline of first line of segment text
  min-width: 80px; // prevents Mantine from truncating badge label with `...` when sibling slug name/script title is long
}

.promptermodal_toast {
  width: 100%;
}

.promptermodal_selectall {
  background-color: wallaby.$theme-gray-1;
  border: 1px solid wallaby.$theme-gray-4;
  border-bottom: 0;
  margin-bottom: -16px;
  padding: 5px 10px;
}

.promptermodal_status {
  margin-left: 10px;
  margin-top: -14px; // override Mantine `Group` spacing for this one instance
}
