
          @use '@scss/all' as wallaby;
        
@import './variables';
@import './generic-layouts';

.scrollingBody {
  @extend .l-wrapper;
  @extend .l-wrapper--scrolling;

  // this is overriding something confusing from wombat-- the height isn't
  // set if we don't do this and the pages all have no breathing room on the bottom
  > div {
    height: fit-content;
  }
}
