.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  letter-spacing: $heading-letter-spacing;
}

.h2 {
  font-size: $t2-font-size;
}

// legacy heading styles currently used in SETTINGS and the LOADING SPINNER

@mixin heading-font {
  font-family: $heading-font-family;
  font-weight: $heading-font-weight;
  letter-spacing: $heading-letter-spacing;
}

.o-heading {
  @include heading-font;
  margin: 0;

  &.o-heading--1 {
    font-size: $t1-font-size;
  }

  &.o-heading--2 {
    font-size: $t2-font-size;
  }

  &.o-heading--3 {
    font-size: $t3-font-size;
  }

  &.o-heading--4 {
    font-size: $t4-font-size;
  }
}
