
          @use '@scss/all' as wallaby;
        
// The rundownEditor holds the top bar, rundown and bottom bar
.rundownEditor {
  background-color: wallaby.$light-white;
  display: flex;
  // the editor itself is full height
  flex: 1;
  flex-direction: column;
}

.rundownEditor_gridWrapper {
  // use the full available height for the rundown grid
  flex-basis: 100%;
  position: relative;
}

// This determines the sizing of the ag-grid component
.rundownEditor_grid {
  height: 100%;
  position: relative;
  width: 100%;
}
