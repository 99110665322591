/* stylelint-disable color-hex-length */

// CODE GENERATED FILE, DO NOT EDIT HERE
// values come from src/theme/colors.ts


// dark
$theme-dark-0: #c1c2c5;
$theme-dark-1: #a6a7ab;
$theme-dark-2: #909296;
$theme-dark-3: #5c5f66;
$theme-dark-4: #373a40;
$theme-dark-5: #2c2e33;
$theme-dark-6: #21222f;
$theme-dark-7: #1a1b1e;
$theme-dark-8: #141517;
$theme-dark-9: #0d0d13;

// gray
$theme-gray-0: #f8f8f8;
$theme-gray-1: #f2f2f2;
$theme-gray-2: #eeeeee;
$theme-gray-3: #d6d6d6;
$theme-gray-4: #cecece;
$theme-gray-5: #adadad;
$theme-gray-6: #868686;
$theme-gray-7: #494949;
$theme-gray-8: #343434;
$theme-gray-9: #212529;

// red
$theme-red-0: #fff5f5;
$theme-red-1: #ffe3e3;
$theme-red-2: #ffc9c9;
$theme-red-3: #ffa8a8;
$theme-red-4: #ff8787;
$theme-red-5: #f92929;
$theme-red-6: #ec0606;
$theme-red-7: #d90000;
$theme-red-8: #bb0000;
$theme-red-9: #9f0000;

// pink
$theme-pink-0: #fff0f6;
$theme-pink-1: #ffdeeb;
$theme-pink-2: #fcc2d7;
$theme-pink-3: #faa2c1;
$theme-pink-4: #f783ac;
$theme-pink-5: #f06595;
$theme-pink-6: #e64980;
$theme-pink-7: #d6336c;
$theme-pink-8: #c2255c;
$theme-pink-9: #a61e4d;

// grape
$theme-grape-0: #f8f0fc;
$theme-grape-1: #f3d9fa;
$theme-grape-2: #eebefa;
$theme-grape-3: #e599f7;
$theme-grape-4: #da77f2;
$theme-grape-5: #cc5de8;
$theme-grape-6: #be4bdb;
$theme-grape-7: #ae3ec9;
$theme-grape-8: #9c36b5;
$theme-grape-9: #862e9c;

// violet
$theme-violet-0: #f1e6ff;
$theme-violet-1: #d3beee;
$theme-violet-2: #b89ade;
$theme-violet-3: #a27fd3;
$theme-violet-4: #8e66c8;
$theme-violet-5: #8252c6;
$theme-violet-6: #6f3db8;
$theme-violet-7: #61369f;
$theme-violet-8: #441589;
$theme-violet-9: #5f3dc4;

// indigo
$theme-indigo-0: #edf2ff;
$theme-indigo-1: #dbe4ff;
$theme-indigo-2: #bac8ff;
$theme-indigo-3: #91a7ff;
$theme-indigo-4: #748ffc;
$theme-indigo-5: #5c7cfa;
$theme-indigo-6: #4c6ef5;
$theme-indigo-7: #4263eb;
$theme-indigo-8: #3b5bdb;
$theme-indigo-9: #364fc7;

// blue
$theme-blue-0: #e7f5ff;
$theme-blue-1: #d0ebff;
$theme-blue-2: #a5d8ff;
$theme-blue-3: #74c0fc;
$theme-blue-4: #4dabf7;
$theme-blue-5: #339af0;
$theme-blue-6: #228be6;
$theme-blue-7: #1c7ed6;
$theme-blue-8: #1971c2;
$theme-blue-9: #1864ab;

// cyan
$theme-cyan-0: #e3fafc;
$theme-cyan-1: #c5f6fa;
$theme-cyan-2: #99e9f2;
$theme-cyan-3: #66d9e8;
$theme-cyan-4: #3bc9db;
$theme-cyan-5: #22b8cf;
$theme-cyan-6: #15aabf;
$theme-cyan-7: #1098ad;
$theme-cyan-8: #0c8599;
$theme-cyan-9: #0b7285;

// teal
$theme-teal-0: #e6fcf5;
$theme-teal-1: #c3fae8;
$theme-teal-2: #96f2d7;
$theme-teal-3: #63e6be;
$theme-teal-4: #38d9a9;
$theme-teal-5: #20c997;
$theme-teal-6: #12b886;
$theme-teal-7: #0ca678;
$theme-teal-8: #099268;
$theme-teal-9: #087f5b;

// green
$theme-green-0: #ebfbee;
$theme-green-1: #d3f9d8;
$theme-green-2: #b2f2bb;
$theme-green-3: #8ce99a;
$theme-green-4: #69db7c;
$theme-green-5: #51cf66;
$theme-green-6: #40c057;
$theme-green-7: #37b24d;
$theme-green-8: #2f9e44;
$theme-green-9: #118000;

// lime
$theme-lime-0: #f4fce3;
$theme-lime-1: #e9fac8;
$theme-lime-2: #d8f5a2;
$theme-lime-3: #c0eb75;
$theme-lime-4: #a9e34b;
$theme-lime-5: #94d82d;
$theme-lime-6: #82c91e;
$theme-lime-7: #74b816;
$theme-lime-8: #66a80f;
$theme-lime-9: #5c940d;

// yellow
$theme-yellow-0: #fff9db;
$theme-yellow-1: #fff3bf;
$theme-yellow-2: #ffec99;
$theme-yellow-3: #ffe066;
$theme-yellow-4: #ffd43b;
$theme-yellow-5: #fcc419;
$theme-yellow-6: #fab005;
$theme-yellow-7: #f59f00;
$theme-yellow-8: #f08c00;
$theme-yellow-9: #e67700;

// orange
$theme-orange-0: #fff4e6;
$theme-orange-1: #ffe8cc;
$theme-orange-2: #ffd8a8;
$theme-orange-3: #ffc078;
$theme-orange-4: #ffa94d;
$theme-orange-5: #ff922b;
$theme-orange-6: #fd7e14;
$theme-orange-7: #f76707;
$theme-orange-8: #e8590c;
$theme-orange-9: #833c01;
