//
// COMMENTS //
//

// container element, contains all threads
.c-commentscontainer {
  left: -202px;
  position: absolute;

  .c-comment__close {
    color: $d1-dark-gray;
    font-size: $base-font-size;
    padding: 5px;
    position: absolute;
    right: -18px;
    text-align: right;
    top: 70px !important; // overrides dynamic inline styling

    &:hover {
      color: $base-blue-gray;
    }
  }
}

// comment counter

.c-commentcount {
  // unique styles
  align-items: center;
  // skeleton button overrides
  background-color: transparent;
  border: 0;
  color: $base-mid-gray;
  display: flex;
  font-family: $base-font-family;
  font-size: $s2-font-size;
  font-weight: bold;
  height: auto;
  left: 34px;
  line-height: $script-line-height;
  padding: 2px 8px;
  position: absolute;

  &:hover {
    color: $d2-dark-gray;
  }

  .c-commentcount__icon {
    font-size: $s3-font-size;
    margin-right: 4px;
  }
}

// threaded comments
.c-commentthread {
  + .c-commentthread {
    margin-top: 5px;
  }

  &.c-commentthread__highlight {
    box-shadow: 0 0 0 4px $highlight-yellow;
  }
}

// comments proper

.c-comment {
  // base
  align-items: flex-start;
  background-color: $d1-dark-gray;
  border-top: 1px solid $l1-dark-gray;
  color: $light-white;
  display: flex;
  flex-direction: column;
  font-family: $base-font-family;
  font-size: $base-font-size;
  justify-content: space-between;
  padding: 8px;
  width: 300px;
  z-index: map-get($zindex, 'editor-ui');

  &:first-child {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
  }

  &:last-child {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  &.c-comment--actions {
    align-items: center;
    border-top: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 8px 0 14px;
  }

  &.c-comment--snippet {
    align-items: flex-start;
    border-top: 0;
    display: flex;
    flex-direction: row;
    padding: 8px 8px 14px;
  }

  &.c-comment--self {
    // layout that accommodates the edit/delete menu for the user's own comments
    padding-right: 0; // leaves room for menu button

    .c-comment__selfrow {
      // layout that accommodates the edit/delete menu for the user's own comments
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  .c-comment__highlight {
    border-left: 3px solid $highlight-yellow-dark;
    margin-left: 2px; // flush left with `resolve` button
    padding: 0 2px 0 8px;
  }

  .c-comment__truncation {
    // warning: additional padding with cause line-clamp to render incorrectly
    -webkit-box-orient: vertical;
    color: $l1-mid-gray;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    overflow: hidden;
    word-break: break-word;
  }

  .c-comment__resolve {
    // styles and layout for this unique button
    border: 1px solid $l1-dark-gray;
    font-size: $s3-font-size;
    padding: 4px 6px;
    text-transform: uppercase;
  }

  .c-comment__byline {
    align-items: center;
    display: flex;
    font-size: $s3-font-size;
  }

  .c-comment__avatar {
    align-self: flex-start;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    height: 26px;
    margin-right: 8px;
    object-fit: cover;
    width: 26px;
  }

  .c-comment__timestamp {
    color: $l1-mid-gray;
  }

  .c-comment__content {
    // the meat and potatoes
    // aka the actual content of the comments
    background-color: $d1-dark-gray;
    color: $light-white;
    flex: 1;
    font-family: wallaby.$base-font-family;
    line-height: 1.4;
    margin: 0 15px 0 35px; // aligns left edge with metadata above it
    resize: none;
    white-space: pre-wrap; // to ensure newlines are rendered
    word-break: break-word;

    &::placeholder {
      color: $l1-mid-gray; // legacy override
      opacity: 1; // browser override
    }

    .c-comment__link {
      align-self: flex-end;
      color: $l2-purple;

      &:hover {
        color: $light-white;
      }
    }
  }

  //
  // COMMENT FORMS //
  //

  // comment as it appears for editing existing comments, writing new comments inline, and writing new threaded comments
  .c-comment__submit {
    margin: 0;

    &:hover {
      color: $light-white;
    }
  }

  // comment as it appears for editing existing comments
  &.c-comment--edit {
    .c-comment__wrapper {
      padding: 0 0 10px 22px;
      width: 280px;

      .c-comment__content {
        &:focus {
          border: 1px solid $d3-purple; // skeleton override
        }
      }

      &::after,
      .c-comment__content {
        border: 1px solid $l1-dark-gray; // skeleton override
        line-height: 1.4;
        margin-left: 5px;
        min-height: initial; // skeleton override
        min-width: 250px; // to align with kebab
        padding: 2px 5px;
      }
    }

    .c-comment__submit {
      align-self: flex-end;
      margin-right: 8px;
    }
  }

  // comment form as it appears both from the inline text menu and at the bottom of existing comments
  &.c-comment--new {
    align-items: center;
    border-top: 1px solid $l1-dark-gray;
    flex-direction: row;
    padding: 8px;

    .c-comment__byline {
      align-self: flex-start; // allows avatar to stay on top left as textarea expands
    }

    .c-comment__avatar {
      margin-right: 0; // aligns new comment placeholder text with previous comments' text above it
    }

    &::after,
    .c-comment__content {
      border: 0;
      hyphens: auto;
      margin: 0;
      min-height: 20px;
      overflow-wrap: break-word;
      resize: none;
      word-wrap: break-word;
    }

    .c-comment__submit {
      align-self: flex-end; // allows button to stay on bottom right as textarea expands
    }
  }

  .c-comment__wrapper {
    display: inline-grid;
    line-height: 1.4;
    margin: 0;

    &::after,
    .c-comment__content {
      // not sure why these styles need to be declared explicitly now
      font-size: $base-font-size;
      font-weight: normal;
      grid-area: 2 / 1;
      grid-area: 1 / 2;
      height: auto; // overrides the 38px default set in skeleton. grrrrr.
      margin: 0;
      overflow: hidden;
      padding: 2px 0 0;
      width: 190px;
      word-wrap: break-word;
    }

    &::after {
      content: attr(data-value) ' ';
      hyphens: auto;
      visibility: hidden;
      white-space: pre-wrap;
    }
  }
}

// dont highlight inline comments in exported PDFs or read only routes
.is-static .e-comment.is-highlighted {
  background-color: unset;
}
