
          @use '@scss/all' as wallaby;
        
.loginSignupButtons {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: absolute;
  right: 20px;
  top: 20px;
  white-space: nowrap;

  @media screen and (max-width: 900px) {
    left: 50%;
    right: 50%;
  }
}

.loginSignupButton {
  border: 1px solid transparent;
  border-radius: 3px;
  color: wallaby.$theme-gray-4;
  font-size: 14px;
  font-weight: 700;
  padding: 2px 8px;

  &:hover {
    color: wallaby.$light-white;
    text-decoration: none;
  }

  &.active {
    border-color: wallaby.$theme-gray-4;
    box-shadow: 0 0 10px wallaby.$theme-dark-7;
  }
}
