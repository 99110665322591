// SIDE-BY-SIDE DIFFER

.c-differ {
  margin-top: 30px;
  max-width: 17in;
  padding: 15px;
  width: 17in;

  &.c-differ--empty {
    margin-top: 150px;
  }
}

.c-differ__line {
  background-color: $light-white;
  flex: 1;
  padding: 10px 20px;
  width: 100%;
  word-break: break-word;

  &.c-differ__line--left {
    margin-right: 10px;
  }

  &.c-differ__line--right {
    margin-left: 10px;
  }

  &.c-differ__line--header {
    font-family: 'Lato', sans-serif;
    text-align: center;
  }

  &.c-differ__line.c-differ__line--collapsed {
    background-color: $l2-light-gray;
    color: $d1-mid-gray;
    padding: 10px;
    text-align: center;
  }

  pre {
    display: inline;
    font-family: $script-font-family;
    font-size: $script-font-size;
    margin-bottom: 0;
    margin-top: 0;
    white-space: pre-line;
  }
}

.c-differ__text {
  display: inline-block;
  font-family: $script-font-family;
  font-size: $script-font-size;
  line-height: normal;
  max-width: 100%;
  white-space: pre-wrap;

  &.c-differ__text--header {
    font-family: $base-font-family;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.c-differ.right-selection .c-differ__line--left pre,
.c-differ.left-selection .c-differ__line--right pre,
.c-differ__row.no-select {
  -webkit-user-select: none;
  user-select: none;
}

// new line icons

.c-differ .new-line-icon {
  padding: 0 20px;
}

.c-differ .new-line-icon .fa {
  transform: translateY(3px);
}

// the actual diffs

.o-diff {
  position: relative;

  &.o-diff--deletion {
    background-color: $deletion-diff-light;
    border-bottom: 2px dashed $deletion-diff-dark;
  }

  &.o-diff--addition {
    background-color: $addition-diff-light;
    border-bottom: 2px solid $addition-diff-dark;
  }
}
