
          @use '@scss/all' as wallaby;
        
.snapshotinfo {
  padding: 0 15px;
}

.snapshotinfo_p {
  color: wallaby.$d1-mid-gray;
}
