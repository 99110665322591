// MODULAR FLEX BOXES

.l-box {
  // blanket flex container for modifiers to be added to
  display: flex;
  // I think this is a case where `!important` is okay
  // it's the most reliable way to override the layout information that's currently nested several layers deep in styled components

  // FLEX DIRECTIONS
  &.l-box--column {
    flex-direction: column;
  }

  // JUSTIFY
  &.l-box--space-around {
    justify-content: space-around;
  }

  &.l-box--space-between {
    justify-content: space-between;
  }

  &.l-box--center {
    justify-content: center;
  }

  &.l-box--end {
    justify-content: flex-end;
  }

  // ALIGN ITEMS
  &.l-box--start-items {
    align-items: flex-start;
  }

  &.l-box--center-items {
    align-items: center;
  }

  &.l-box--baseline-items {
    align-items: baseline;
  }

  // CUSTOM WIDTHS
  &.l-box--script {
    // for flex containers that need to match the width of a script,
    // e.g. subheader or toolbar above editor
    width: 8.5in;

    @media screen and (max-width: 10in) {
      width: auto;
    }
  }
}

// ROWS & COLUMNS
// can be mixed and matched with `l-box` and friends

.l-row {
  align-items: center;
  display: flex;

  .l-row__column {
    margin: auto 10px;
  }
}

// 2019 PROTO-BEM LAYOUT 🛑
// layout and `l-` prefix experiments from when we were first figuring out BEM in 2019
// proceed with caution

.wrapper {
  float: none;
  margin: 0 auto;
  max-width: 1100px;

  @media screen and (max-width: 980px) {
    margin: 0 20px;
  }
}

.wrapper--dark {
  background-color: $d1-blue-gray;
}

// agnostic grids and flex layouts

.l-wrap {
  // boilerplate grid
  display: grid;
  grid-column-gap: 1em;

  &.l-wrap--twocol {
    // dead simple two column one row
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

// global utilities

.l-lonebuttonrow {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px auto 40px;
}

// grids

.l-grid {
  display: grid;
}

// forms

.l-form {
  .l-form__formfield {
    margin-bottom: 15px;
  }

  .is-stacked {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

// modals

.l-modal {
  margin: 5% auto;
  max-width: 500px;

  @media screen and (max-width: 600px) {
    margin: 5% 20px;
  }

  .l-modaltext__toggle {
    margin-bottom: 20px;
    width: 400px;

    @media screen and (max-width: 600px) {
      width: auto;
    }
  }

  .l-modalcontent__notification {
    margin: auto auto 10px;
  }
}

.l-modal__filebox {
  margin: 10px auto;
}

// notifications

.l-notification {
  margin-bottom: 20px;
}

// editor

.l-editor {
  height: 100%;
  min-height: 100vh;
}

// search

.l-searchresults {
  margin-bottom: 30px;
}

// utility class to approximate the style of legacy buttons
.l-button-margin {
  margin: 10px auto;
}

// pushes the content in settings down below the toolbar and header
.l-settings-container {
  margin-top: 15px;
}
