// legacy search

.o-search {
  background-color: $base-blue-gray;
  border-radius: 3px;

  .o-search__input {
    background-color: $base-blue-gray;
    border: 0;
    color: $light-white;
    font-family: $base-font-family;
    font-size: $s1-font-size;
    height: 28px;
    max-width: 220px;
    padding: 0 10px;

    &:focus {
      border: 0;
      box-shadow: none;
    }

    @media screen and (max-width: 800px) {
      font-size: $s2-font-size;
      max-width: 130px;
    }
  }

  .o-search__button {
    background-color: transparent;
    border: 0;
    color: $light-white;
    font-size: $s1-font-size;
    opacity: 0.4;
    padding: 0 10px 0 15px;

    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}
