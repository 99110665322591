
          @use '@scss/all' as wallaby;
        
.leftSection {
  overflow: hidden;
}

.rightSection {
  flex-basis: content;
  flex-shrink: 0;
  min-width: fit-content;
}
