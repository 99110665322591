
          @use '@scss/all' as wallaby;
        
.collaborators_avatar {
  // we set border color at runtime using info from socket messages
  &:hover {
    cursor: pointer;
  }
}

.collaborators_menuAvatar {
  margin-left: 0;
  margin-right: 3px;
}
