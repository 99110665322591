
          @use '@scss/all' as wallaby;
        
// preview/example
.prefModal_exampleOuter {
  background-color: #f0f0f0;
  height: 90px; // avoid jitter when switching modal tabs
  padding: 20px;
  padding-bottom: 0;
}

.prefModal_exampleOuter___footer {
  padding-bottom: 20px;
  padding-top: 0;
}

.prefModal_exampleInner {
  background-color: #fff;
  height: 70px;
  padding: 20px;
}

.prefModal_exampleContent {
  height: 28px;
}

.prefModal_exampleBookend {
  flex: 1;
}
