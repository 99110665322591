// avatars

.o-avatar {
  background-color: $light-white;
  background-position: center;
  background-size: cover;
  border: 2px solid $light-white;
  border-radius: 50%;

  &.is-red {
    border: 2px solid $red-user-color;
  }

  &.is-indigo {
    border: 2px solid $indigo-user-color;
  }

  &.is-teal {
    border: 2px solid $teal-user-color;
  }

  &.is-gold {
    border: 2px solid $gold-user-color;
  }

  &.is-orange {
    border: 2px solid $orange-user-color;
  }

  &.is-blue {
    border: 2px solid $blue-user-color;
  }

  &.is-brown {
    border: 2px solid $brown-user-color;
  }

  &.is-gray {
    border: 2px solid $gray-user-color;
  }
}
