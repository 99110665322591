// ANIMATIONS

// mixins for convenient storage

@mixin delay-appearance-animation {
  animation-delay: 0.4s;
  animation-duration: 0s;
  animation-fill-mode: forwards;
  animation-name: delay-appearance;
  opacity: 0;
}

// keyframes

@keyframes delay-appearance {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
