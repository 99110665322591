//
// LEGACY PRESENCE DOTS / USER ICONS //
// (we used both terms interchangably) //
//

.user-icon-container,
.read-only-icon-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.user-icon {
  align-items: center;
  border-radius: 100%;
  color: #fff;
  display: flex;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  justify-content: center;
  margin: 5px;
  position: relative;
  text-align: center;
  text-transform: none;
  width: 40px;
}

.user-icon:last-of-type {
  margin-right: 0;
}

.ellipsis {
  letter-spacing: 2px;
}

// presence dot colors

.red-user {
  background-color: #d50000;
}

.indigo-user {
  background-color: #483d8b;
}

.teal-user {
  background-color: #00a4a6;
}

.gold-user {
  background-color: #b8860b;
}

.orange-user {
  background-color: #e65722;
}

.blue-user {
  background-color: #1460aa;
}

.brown-user {
  background-color: #804028;
}

.gray-user {
  background-color: #939393;
  padding-top: 6px;
}
