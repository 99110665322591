
          @use '@scss/all' as wallaby;
        
// icons
.statusBar_icon,
.statusBar_icon svg {
  // can be replaced with actual custom icon, which I've added to our FontAwesome kit
  // it just needs some troubleshooting
  background-color: transparent;
  border: transparent;
  color: wallaby.$theme-gray-6;
  fill: wallaby.$theme-gray-6; // to match ActionIcon
  font-size: 14px;
  width: 12px;
}

.statusBar_icon___active,
.statusBar_icon___active svg {
  color: wallaby.$theme-violet-6;
  fill: wallaby.$theme-violet-6;
}
