// TINY MODAL
// a small, no-frills modal that can be positioned anywhere on the screen
// uses script font styles by default now, but that can be amended with modifiers later
// used in the ProseMirror character autocomplete plugin

.o-tinymodal {
  background-color: $light-white;
  border: 1px solid $d1-light-gray;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(190, 190, 190, 0.3);
  font-family: $script-font-family;
  padding: 5px;
  text-transform: uppercase;

  .o-tinymodal__text {
    // accomodates for courier's weird centering
    padding: 2px 4px 0;

    &:hover {
      cursor: pointer;
    }

    &:hover,
    &.o-tinymodal__text--selected {
      background-color: $d1-light-gray;
      border-radius: 3px;
    }
  }
}
