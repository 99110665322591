// LEGACY HOVER TOOLTIP

.o-hovertooltip[data-tooltip] {
  &::after {
    // centers tooltip under button because the button it lives in displays as a flex column
    animation-delay: 0.4s;
    animation-duration: 0s;
    animation-fill-mode: forwards;
    animation-name: delay-appearance;
    background-color: $d1-dark-gray;
    border-radius: 3px;
    box-shadow: 0 0 4px rgba(190, 190, 190, 0.3);
    color: $light-white;
    content: attr(data-tooltip);
    display: none;
    font-family: $base-font-family;
    font-size: $base-font-size;
    letter-spacing: normal;
    opacity: 0;
    padding: 5px 15px 7px;
    position: absolute;
    text-align: center;
    text-transform: none;
    top: 45px;
    white-space: nowrap;
    z-index: map-get($zindex, 'tooltip');
  }

  &:hover::after {
    display: block;
  }

  &.o-hovertooltip--small {
    &::after {
      font-size: $s2-font-size;
      padding: 4px 8px 5px;
    }
  }

  &.o-hovertooltip--dash {
    &::after {
      top: 20px;

      @media screen and (max-width: 600px) {
        align-self: flex-end;
      }
    }
  }

  &.o-hovertooltip--settings {
    &::after {
      top: 20px;

      @media screen and (max-width: 600px) {
        align-self: flex-end;
      }
    }
  }
}

// click / interactive tooltip

.o-clicktooltip {
  background-color: $d2-dark-gray;
  border-radius: 3px;
  color: $light-white;
  display: none;
  font-family: $base-font-family;
  padding: 5px 15px;
  position: absolute;
  text-align: center;
  text-transform: none;
  top: 45px;
  white-space: nowrap;
  z-index: map-get($zindex, 'tooltip');

  &.is-open {
    display: block;
  }

  input {
    // for inputs inside tooltips
    // makes bg dark and text light
    background-color: $d1-blue-gray;
    border: 1px solid $base-blue-gray;
    color: $light-white;
    padding: 6px;

    &:focus {
      border: 1px solid $d1-purple;
    }
  }
}

// hoverlist
// like a tooltip, but with more info and a light background

.o-hoverlist {
  background-color: $light-white;
  border: 1px solid $base-light-gray;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(190, 190, 190, 0.3);
  padding: 10px 15px;
  z-index: map-get($zindex, 'tooltip');
}

// SPECIFIC TOOLTIP POSITIONING

// EDITOR SIDEBAR

.c-sidebutton.o-hovertooltip[data-tooltip]::after {
  right: 40px;
  top: 2px;
}

.c-sidebarpanel__close[data-tooltip]::after {
  right: 6px;
  top: 36px;
}

.c-sidebarpanel__button.o-hovertooltip[data-tooltip]::after {
  top: 4px;
}

.c-sidebarpanel__button--back.o-hovertooltip[data-tooltip]::after {
  left: 8px;
  top: 36px;
}

.c-sidebarpanel__button--close.o-hovertooltip[data-tooltip]::after {
  right: 4px;
  top: 36px;
}

// DASHBOARD

// truncated breadcrumb links
.o-breadcrumb__link.o-hovertooltip[data-tooltip]::after {
  align-self: center;
  font-size: 14px;
  top: -35px;
}

// NEW COMPONENT TOOLTIP
// allows for automatic centering, nested classes, and unrelated c- and o-prefixed children

:not(.is-open) > .c-tooltip[data-tooltip] {
  align-items: center;
  display: flex;
  flex-direction: column;

  &::after {
    // imports
    @include delay-appearance-animation;
    // styles
    background-color: $d1-dark-gray;
    border-radius: 3px;
    box-shadow: 0 0 4px rgba(190, 190, 190, 0.3);
    color: $light-white;
    // layout and display
    content: attr(data-tooltip);
    display: none;
    font-family: $base-font-family;
    font-size: $base-font-size;
    padding: 5px 15px;
    position: absolute;
    text-transform: none;
    top: 35px;
    white-space: nowrap;
    z-index: map-get($zindex, 'tooltip');
  }

  &:hover::after {
    display: block;
  }

  &.c-dropdown.is-open:hover::after {
    // so that tooltips describing dropdowns don't display when the dropdown is already open
    display: none;
  }

  &.c-tooltip--left[data-tooltip] {
    align-items: flex-start;
  }

  &.c-tooltip--flush-left[data-tooltip]::after {
    left: 0;
  }

  &.c-tooltip--right[data-tooltip] {
    align-items: flex-end;
  }

  &.c-tooltip--flush-right[data-tooltip]::after {
    right: 0;
  }

  &.c-tooltip--small[data-tooltip] {
    &::after {
      font-size: $s2-font-size;
      padding: 4px 8px;
    }
  }
}
