
          @use '@scss/all' as wallaby;
        
.content {
  border-radius: 3px;
  font-size: 16px;
  overflow: 'hidden';
}

.close {
  color: var(--mantine-color-dark-9);
  font-size: 16px;

  &:hover {
    background-color: var(--mantine-color-gray-3);
  }
}

.header {
  background: var(--mantine-color-gray-1);
  border: 1px solid var(--mantine-color-gray-3);
  height: 35px;
  margin: 0;
  padding-left: 15px;
  padding-right: 5px;
}

.body {
  padding: 15px;
}

.title {
  font-weight: 700;
  line-height: 1.2;
}
