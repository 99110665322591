//
// LEGACY TOGGLE ROW //
//

// nav row

.ter-nav {
  align-items: flex-start;
  display: flex;
}

// script / edit toggle

.button-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.view-toggle {
  margin-right: 20px;
}

.toggle-wrapper {
  display: flex;
  flex-wrap: nowrap;
  margin-right: 10px;
}

.toggle-btn {
  background: #efefef; /* For browsers that do not support gradients */
  background: linear-gradient(#f4f4f4, #efefef); /* Standard syntax */
  border: 1px solid #c0c0c0;
  color: #7c7c84;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  height: 40px;
  letter-spacing: 1.2px;
  margin: -2px;
  padding: 0 20px;
  text-transform: uppercase;
}

.toggle-btn:hover {
  background: #efefef; /* For browsers that do not support gradients */
  background: linear-gradient(#fff, #f6f6f6); /* Standard syntax */
  border: 1px solid #c0c0c0;
  color: #7c7c84;
  font-family: 'Montserrat', sans-serif;
  margin: -2px;
}

.toggle-btn:focus {
  background: linear-gradient(#efefef, #f4f4f4); /* Standard syntax */
  border: 1px solid #c0c0c0;
  color: #555;
}

.edit-btn {
  border-radius: 3px 0 0 3px;
}

.side-btn {
  border-radius: 0;
}

.script-btn {
  border-radius: 0 3px 3px 0;
}

.selected-btn,
.selected-btn:hover,
.selected-btn:focus {
  background: #f9f9f9; /* For browsers that do not support gradients */
  background: linear-gradient(#f6f6f6, #fff); /* Standard syntax */
  border: 1px solid #c0c0c0;
  color: #0d0d13;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  height: 40px;
  letter-spacing: 1.2px;
  margin: -2px;
}

.page-count {
  margin-top: 5px;
}

// nav buttons

.nav-btn {
  align-items: center;
  background: #efefef; /* For browsers that do not support gradients */
  background: linear-gradient(#f4f4f4, #efefef); /* Standard syntax */
  border: 1px solid #c0c0c0;
  color: #7c7c84;
  display: flex;
  flex-direction: column;
  font-family: 'Lato', sans-serif;
  font-size: 14px;
  justify-content: center;
  margin: -2px 5px 0 10px;
  padding: 0 10px;
  position: relative;
  text-transform: none;
  width: 38px;
}

.nav-btn:hover {
  background: #efefef; /* For browsers that do not support gradients */
  background: linear-gradient(#fff, #f6f6f6); /* Standard syntax */
  border: 1px solid #c0c0c0;
  color: #7c7c84;
}

.selected-nav-btn {
  /* must be below .nav-btn */
  background: #efefef; /* For browsers that do not support gradients */
  background: linear-gradient(#f6f6f6, #fff); /* Standard syntax */
  color: #0d0d13;
}
