// zoom-friendly comment button

.newcomment {
  align-items: center;
  background-color: $d1-dark-gray; // default `button` override
  border: 0; // default `button` override
  border-radius: 20px;
  box-shadow: 0 0 10px rgba(190, 190, 190, 0.5);
  color: $light-white;
  display: flex;
  font-size: 18px;
  height: 40px;
  justify-content: center;
  left: 76px;
  position: absolute;
  width: 40px;
  z-index: 1;

  &:hover {
    background-color: $l1-dark-gray;
  }
}
