
          @use '@scss/all' as wallaby;
        
.goldtooth {
  position: relative;
}

.goldtooth::before {
  background-color: #efc533;
  bottom: -2px;
  content: '';
  display: inline-block;
  height: 8px;
  left: -2px;
  position: absolute;
  width: 8px;
}
