// plain badges

.o-badge {
  align-items: center;
  background-color: $d3-purple;
  border-radius: 3px;
  color: $light-white;
  display: flex;
  font-size: 11px;
  font-weight: bold;
  padding: 2px 4px;
  text-transform: uppercase;
  white-space: nowrap;

  &.o-badge--inline {
    // for badges that are inside of other elements, like buttons
    margin-left: 4px;

    &.o-badge--open,
    &.o-badge--limited,
    &.o-badge--private {
      // more space for badges in search results
      // nesting so non-permissions badges won't be affected
      margin-left: 8px;
    }
  }

  &.o-badge--gray {
    background-color: $base-blue-gray;
  }

  &.o-badge--green {
    background-color: $success-color;
  }

  &.o-badge--blue {
    background-color: $blue-user-color;
  }
}

// button badges

.o-badge.o-badge--button {
  border: 0;
  box-shadow: 0 1px 5px rgb(190 190 190 / 20%);
  height: 28px;

  &:hover {
    cursor: pointer;
  }

  &.o-badge--green:hover {
    background-color: darken(
      $success-color,
      10%
    ) !important; // override any conflicting button styles
  }

  &.o-badge--gray:hover {
    background-color: darken(
      $base-blue-gray,
      10%
    ) !important; // override any conflicting button styles
  }

  &.o-badge--blue:hover {
    background-color: darken(
      $blue-user-color,
      10%
    ) !important; // override any conflicting button styles
  }
}

// iconic badges

.o-badge {
  &.o-badge--iconic {
    font-size: 12px;
    padding: 4px 8px;

    &::before {
      display: inline-block;
      -webkit-font-smoothing: antialiased;
      margin-right: 5px;
      text-rendering: auto;
    }
  }

  &.o-badge--open {
    @extend .o-badge--green;
    @extend .o-badge--iconic;

    &::before {
      content: '\f52b';
      font: var(--fa-font-solid);
    }
  }

  &.o-badge--limited {
    @extend .o-badge--gray;
    @extend .o-badge--iconic;

    &::before {
      content: '\f500';
      font: var(--fa-font-solid);
    }
  }

  &.o-badge--private {
    @extend .o-badge--blue;
    @extend .o-badge--iconic;

    &::before {
      content: '\f6f3';
      font: var(--fa-font-solid);
    }
  }
}

// mini badges

.o-badge.o-badge--mini {
  &.o-badge--iconic {
    font-size: 10px;
    padding: 4px;

    &::before {
      margin-right: 0;
    }
  }
}
