
          @use '@scss/all' as wallaby;
        
.rundownOutline {
  overflow-y: scroll;
  padding: 5px;
}

.scriptItem {
  @include wallaby.listing-background(selected);

  border-radius: 3px;
  cursor: pointer;
  overflow: hidden;
  padding: 5px;
  text-overflow: ellipsis;
  user-select: none;
  white-space: nowrap;
}
