// BREBCRUMB
.o-breadcrumb {
  align-items: center;
  display: flex;
  position: relative;

  .o-breadcrumb__item {
    margin: 2px 0; // even on both sides for maximum horizontal centering

    &.o-breadcrumb__item--slash {
      color: $d1-mid-gray;
      margin: auto 10px;
    }
  }

  .o-breadcrumb__collapsible {
    @media screen and (max-width: 1320px) {
      display: none;
    }
  }

  .o-breadcrumb__link {
    display: flex;
    flex-direction: column;

    .o-breadlink__text {
      font-size: $base-font-size;
      max-width: 160px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .o-breadcrumb__icon {
    margin-right: 5px;
  }

  .o-breadcrumb__link,
  .o-breadcrumb__slash {
    margin: 0 2px;
  }

  .o-breadcrumb__link {
    align-items: baseline;
    border-radius: 3px;
    color: $d2-dark-gray;
    display: flex;
    flex-direction: row;
    font-size: $s1-font-size;
    font-weight: bold;
    padding: 4px 8px;

    &:hover {
      background-color: $l3-light-gray;
      cursor: pointer;
      text-decoration: none; // default a tag override
    }

    &.o-breadcrumb__link--truncated {
      // for links cut off with an ellipsis
      display: inline;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media screen and (max-width: 1420px) {
        max-width: 150px;
      }

      @media screen and (max-width: 1220px) {
        max-width: 80px;
      }
    }

    &.o-breadcrumb__link--hidden {
      // for links and groups of entirely-hidden links

      &::after {
        content: '...';
      }
    }
  }

  .o-breadcrumb__slash {
    color: $d1-mid-gray;
  }
}
