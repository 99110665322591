
          @use '@scss/all' as wallaby;
        
@import '../../layouts/variables';

.explorerButtons {
  align-items: center;
  background: wallaby.$light-white;
  border-right: 1px solid wallaby.$theme-gray-3;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: var(--sidestrip-width);
}

.explorerButtons_logo {
  display: flex;
  height: 38px;
  justify-content: center;
  margin: 10px 5px;
  padding: 8px;
  width: 38px;

  img {
    height: 22px;
  }

  &:hover {
    background-color: wallaby.$theme-gray-1;
  }
}

.explorerButtons_button {
  background-color: transparent;
  border-radius: 3px;
  display: flex;
  font-size: 15px;
  justify-content: center;
  margin: 2px 5px;
  padding: 12px 6px;

  &:hover {
    background-color: wallaby.$theme-gray-1;
  }
}

.explorerButtons_button__selected {
  background-color: wallaby.$d1-purple;
  color: wallaby.$light-white;

  &:hover {
    background-color: darken(wallaby.$d1-purple, 2%);
    color: wallaby.$light-white;
  }
}
