@use '../../wombat-scss/partials/variables' as *;

// SCRIPT ELEMENTS
// ---------------

// DOC
// ProseMirror class is equivalent to the document node
.ProseMirror {
  &.is-static {
    /*
      In live scripts, element numbers are placed in an <input>.
      In static/compare/PDF, we place them using a pseudo selector.

      The horizontal positioning of the element number is dynamically
      computed using the left margin of the element type.
    */
    [data-element-number] {
      position: relative;
    }

    [data-element-number]::before {
      // we need to override any block-specific styles for color, bold
      // outline, underline
      color: $d2-dark-gray;
      content: attr(data-element-number);
      font-weight: normal;
      position: absolute;
      text-decoration: none;
      text-transform: none;
      top: 0;
    }
  }

  /* show lock icon for locked pages in browser views */
  @media screen {
    .o-page[data-locked='true']::after {
      color: $base-mid-gray;
      content: '\f023';
      font: var(--fa-font-solid);
      position: absolute;
      right: -1.5em;
      text-align: right;
      top: 0.5em;
    }
  }
}

/*
  Everything below is copied from the prototype.
  I'm not 100% sure what it all does, but figured we might as well keep it
  the same in both places until we determine what's necessary.
*/
.ProseMirror-hideselection *::selection {
  background: transparent;
}

.ProseMirror-hideselection {
  caret-color: transparent;
}

.ProseMirror-selectednode {
  outline: 2px solid #8cf;
}

.ProseMirror-gapcursor {
  display: none;
  pointer-events: none;
  position: absolute;
}

.ProseMirror-gapcursor::after {
  border-top: 1px solid #000;
  content: '';
  display: block;
  position: absolute;
  top: -2px;
  width: 20px;
}

.ProseMirror-focused .ProseMirror-gapcursor {
  display: block;
}

/* styles for our remote cursors */
.js-remote-cursor {
  border-right: 2px solid #483d8b;
  margin-left: -1px;
  margin-right: -1px;
}

// styles specific to screen, don't want these in print
@media screen {
  .ProseMirror {
    // full viewport height minus header offset
    min-height: calc(100vh - 50px);
    position: relative;
  }
}

// PAGE
// 8.5 x 11 in (US standard letter size)
.o-page {
  font-family: $script-font-family;
  font-size: $script-font-size;
  line-height: $script-line-height;

  // The first block element in a page has no top margin
  // This applies to first element in dual dialogue too (character).
  // We _also_ want to remove top margin for dual dialogue on new pages.
  // TODO: likely broken on screen (because of margin slots plugin
  .o-block-element:first-child,
  .o-dual-dialogue:first-child {
    margin-top: 0;
  }

  @media screen {
    // Create an 11" tall background on the page so that if the page text gets
    // too long, it will overflow the background just like it would the printed page
    background: linear-gradient(
      to bottom,
      $light-white 11in,
      0%,
      transparent calc(100% - 11in)
    );

    // using min-height to allow overflow between repagination
    min-height: 11in;

    // Using padding for margins for reasons
    padding: 1in 1in 1in 1.5in;
    position: relative; // needed for page numbering
    width: 8.5in;

    + .o-page {
      margin-top: 0.5in;
    }
  }
}

// Block Elements
// --------------

// Many styles for block elements are configured dynamically at runtime using
// a per-show, data-driven configuration. The following things should NOT be defined here:
//    margin (except to set a default of 0)
//    width
//    lineHeight
//    color  (except to set a default)
//    font stylings such as: uppercase, underline, bold, italic
.o-block-element {
  margin: 0;
  min-height: $script-font-size;
  overflow-wrap: break-word;
  // TODO: use `white-space: break-spaces` when it's ready to be adopted
  // context: https://www.chromestatus.com/feature/4842781306519552
  // not yet supported in FF or Safari
  position: relative;
  // allows pseudo-elements to be absolutely positioned
  // according to their respective block elements
  white-space: pre-wrap;

  // The data driven configs set a standard alignment for the block type but
  // when a user overrides that we use these classes (and need the !important to
  // override the more specific config-driven block styles
  &.is-left-aligned {
    text-align: left !important;
  }

  &.is-right-aligned {
    text-align: right !important;
  }

  &.is-center-aligned {
    text-align: center !important;
  }

  &.has-active-menu {
    background-color: $l3-light-gray;
    box-shadow: 0 0 0 10px $l3-light-gray;
  }

  // ProseMirror inserts a <br> in empty blocks, but we don't do that when
  // printing, so we need a minimum height to ensure they don't collapse.
  // https://discuss.prosemirror.net/t/how-to-preserve-br-tags-in-empty-paragraphs/2051/3
  &:empty {
    height: $script-font-size;
  }
}

// IN-SCRIPT ANCHOR TAGS

.o-block-element a,
.o-block-element a:hover {
  color: #0000f7;
  text-decoration: underline;
}

.o-block-element a:visited {
  color: #551a8b;
}

@media print {
  .o-block-element a {
    color: var(--link-color, #0000f7);
  }
}

// DUAL_DIALOGUE
// NOTE: all overrides for dual dialogue OTHER than this
// are driven by the data configs
.o-dual-dialogue {
  display: flex;
  justify-content: space-between;
}

// EXCLUDED FROM TIMING
// NOTE: This defines the styles for the marker for excluded from timing
// but the positioning is computed based on the data-driven styles
@media screen {
  .o-block-element.is-untimed::before {
    align-items: center;
    border-left: 2px dashed $d2-light-gray;
    color: $d3-light-gray;
    content: '\e911';
    display: flex;
    font-family: 'icomoon';
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    height: 100%;
    line-height: 1;
    padding-left: 10px;
    position: absolute;
    text-transform: none;
    width: 3px;
  }

  /* we don't display a timing decoration
  in screenplays or snapshot mode */
  .is-static .o-block-element.is-untimed::before,
  .is-screenplay .o-block-element.is-untimed::before {
    display: none;
  }

  /* omit the icon for directly adjacent blocks */
  .o-block-element.is-untimed + .o-block-element.is-untimed::before {
    content: '';
  }
}
