
          @use '@scss/all' as wallaby;
        
.commentHistory_dropdown {
  border: 1px wallaby.$theme-gray-3 solid;
  box-shadow: 0 0 4px rgba(190, 190, 190, 0.3);
  padding: 0;
}

.commentHistory_header {
  background-color: wallaby.$theme-gray-2;
  padding: 5px 5px 5px 10px;
}

.commentHistory_filter {
  background-color: wallaby.$theme-gray-2;

  &:hover {
    background-color: wallaby.$theme-gray-4;
  }
}

.commentHistory_caret {
  margin-left: 4px;
}

// in the corresponding component we calculate the maxHeight dynamically
.commentHistory_list {
  max-height: 70vh;
  overflow: auto;
  padding: 10px 15px;
}

.commentHistory_footer {
  border-top: 1px wallaby.$theme-gray-3 solid;
  display: flex;
  padding: 10px;
}

// the comments, themselves

.comment_card {
  background-color: wallaby.$theme-dark-7;
  color: wallaby.$light-white;
  overflow: visible;
  white-space: normal; // overrides a parent `nowrap` in toolbar
}

.comment_contents {
  align-content: flex-end;
  display: flex;
  flex-direction: column;
}

.comment_quote {
  color: wallaby.$theme-gray-4;
  flex: 1;
  font-size: 16px;
  padding: 0;
}

.comment_quoteicon {
  margin-right: 12px; // to light up with comment text below
  margin-top: 0; // Matine override
}

.comment_quoteinner {
  margin-bottom: 10px;
  padding: 5px 10px 5px 15px;
}

.comment_byline {
  align-items: flex-start;
  display: flex;
}

.comment_text {
  margin-left: 8px; // gives space between avatar
}

.comment_author {
  color: wallaby.$theme-gray-5;
  font-weight: bold;
  margin-right: 3px;
}

.comment_link {
  color: wallaby.$theme-violet-1; // could be themefied for dark bacgrounds--maybe it already is?
  text-align: right;

  &:hover {
    color: wallaby.$theme-violet-0;
  }
}
