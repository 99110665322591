
          @use '@scss/all' as wallaby;
        
.loader {
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-name: fadeInOpacity;
  animation-timing-function: ease-in;
  opacity: 0;
}

.loader__fullScreen {
  inset: 0;
  position: fixed;
}
