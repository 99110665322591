
          @use '@scss/all' as wallaby;
        
@import './variables';
@import './generic-layouts';
@import './ScrollingBody.module';

.centeredScrollingBody {
  @extend .scrollingBody;
  @extend .l-wrapper--align-content-center;
  @extend .l-wrapper--flex-row;
  @extend .l-wrapper--justify-center;
  padding: 40px;
}
