
          @use '@scss/all' as wallaby;
        
.archivedScript {
  background-color: #fff;
}

.archivedScript_content {
  padding: 10px;
}

.archivedScript_linkedLine {
  margin-bottom: 10px;
}

.archivedScript_anchor {
  word-break: break-all;
}
