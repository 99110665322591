.s-table {
  width: 100%;

  &.s-table--sticky {
    position: relative;
  }

  .s-table__header {
    background-color: #f2f2f2;

    &.s-table__header--sticky {
      position: sticky;
      top: 45px;
    }
  }

  .s-table__header,
  .s-table__data {
    border-bottom: 1px solid #e1e1e1;
    padding: 12px 15px;
    text-align: left;
  }

  .s-table__header:first-child,
  .s-table__data:first-child {
    padding-left: 0;
  }

  .s-table__header:last-child,
  .s-table__data:last-child {
    padding-right: 0;
  }

  .s-table__data:not(:first-child),
  .s-table__header:not(:first-child) {
    text-align: center;
  }
}
