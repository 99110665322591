
          @use '@scss/all' as wallaby;
        
.body {
  align-items: 'center';
}

.input:checked + .track {
  background-color: var(--mantine-color-green-9);
  border-color: var(--mantine-color-green-9);
}

.root[data-size='xs'] .labelWrapper {
  font-size: 16px;
}

.root[data-size='sm'] .labelWrapper {
  font-size: 18px;
}

.root[data-size='md'] .labelWrapper {
  font-size: 20px;
}

.root[data-size='lg'] .labelWrapper {
  font-size: 22px;
}

.root[data-size='xl'] .labelWrapper {
  font-size: 24px;
}
