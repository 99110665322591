// RADIO BUTTONS

.c-radiogroup {
  margin-top: -20px; // compensates for the invisible default radio buttons that are still taking up space on the screen

  &.c-radiogroup--fieldset {
    border: 0; // overrides `fieldset` defaults
    margin-top: 0; // overrides custom
    padding: 0; // overrides `fieldset` defaults
  }

  &.c-radiogroup--two-col {
    display: grid;
    grid-column-gap: 1em;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
}

.c-radio {
  &.c-radio--stacked {
    margin-top: -20px; // compensates for the invisible default radio buttons that are still taking up space on the screen
  }

  &.c-radio--stacked:not(:last-of-type) {
    // for radios that are stacked on top of each other instead of displayed in a single row
    margin-bottom: 10px;
  }

  .c-radio__label {
    // how individual radio buttons appear by default
    align-items: center;
    background-color: $light-white;
    border: 1px solid $d1-light-gray;
    border-radius: 3px;
    display: flex;
    font-size: $base-font-size;
    font-weight: 400;
    justify-content: space-between;
    outline: 1px solid transparent;
    padding: 10px;
    position: relative;

    &:hover {
      background-color: $l3-light-gray;
      cursor: pointer;
    }

    &.c-radio__label--disabled {
      background-color: $light-white;
      border: 1px solid $l2-light-gray;
      color: $d1-mid-gray;

      &:hover {
        background-color: $light-white;
        cursor: default;
      }

      .c-radiolabel__dot {
        background-color: $light-white;
      }
    }

    .c-radiolabel__dot {
      background-color: $light-white;
      border: 1px solid $d1-light-gray;
      border-radius: 50%;
      height: 20px;
      width: 20px;
    }
  }

  .c-radio__fineprint {
    color: $d1-mid-gray;
    font-size: $s2-font-size;
    margin-top: 5px;

    &.c-radio__fineprint--absolute {
      position: absolute;
      top: 40px;
    }
  }
  /*
  the following styles break a lint rule (`selector-no-qualifying-type`), but since they'd be difficult and time-consuming to fix, we've made the conscious decision to leave them be
  */

  /* stylelint-disable */
  input[type='radio'] {
    opacity: 0; // using this on default buttons instead of `display: none;` or `visiblity: hidden;` keeps radios keyboard focusable
    visibility: visible;
  }

  input[type='radio']:checked + label {
    // styles selected option
    background-color: $light-white;
    border: 1px solid $d1-purple;
    box-shadow: inset 0 0 1px $d1-purple;
    color: $d2-dark-gray;

    .c-radiolabel__dot {
      border: 7px solid $d1-purple;
    }
  }

  // in push-to-prompter modal,
  // this toggles the visibility of the slug list based on whether or not the `individual slugs` radio is selected
  input[type='radio'] + label + .c-modal__scroll {
    display: none;
  }

  input[type='radio']:checked + label + .c-modal__scroll {
    display: block;
  }

  // in export settings modal,
  // this toggles the visibility of second hidden set of radio buttons

  input[type='radio'] + label + .c-radio__secret {
    display: none;
  }

  input[type='radio']:checked + label + .c-radio__secret {
    display: block;
    margin-top: 50px;
  }
  /* stylelint-enable */
}
