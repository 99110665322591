
          @use '@scss/all' as wallaby;
        
.onboardingLink {
  color: wallaby.$theme-violet-2;

  &:hover {
    color: wallaby.$light-white;
  }
}

.backarrow {
  color: wallaby.$theme-violet-2;
  font-size: 13px;
  margin-right: 8px;
}

.divider {
  border-color: wallaby.$theme-gray-7;
  margin: 15px 0;
  width: 180px;
}
