
          @use '@scss/all' as wallaby;
        
.userselect_input {
  margin-bottom: 10px;
  min-width: 300px;
}

.userselect_dropdown {
  max-height: 50vh;
  overflow-y: auto;
}
