.c-commentcounter {
  align-items: center;
  border: 0;
  border-radius: 3px;
  color: $l1-dark-gray;
  display: flex;
  flex-direction: column;
  font-family: $base-font-family;
  font-size: $base-font-size;
  height: auto;
  padding: 4px;

  &:hover {
    background-color: $l1-light-gray;
  }

  &.o-hovertooltip[data-tooltip] {
    &::after {
      font-family: $base-font-family;
      top: 40px;
    }
  }

  .c-commentbutton__icon {
    align-items: center;
    display: flex;

    img {
      margin-right: 2px;
      width: 15px;
    }

    .c-commenticon__number {
      font-size: $s1-font-size;
    }
  }
}
