// FORMS

// light form with dark text

.c-form {
  .c-form__heading {
    margin-bottom: 15px;
  }

  .o-formfield {
    &.is-stacked {
      align-items: flex-start;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
    }

    .o-formfield__label {
      display: block;
      font-size: $s1-font-size;
      margin-bottom: 0;
    }
  }

  .o-formfield--picwrap {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .o-formfieldpicwrap__pic {
      margin: 5px 0;
      max-width: 60px;
    }
  }

  .o-formfield__input {
    width: 100%;
  }
}

// dark form with light text

.c-form.c-form--dark,
.c-form .c-form__text {
  background-color: $d1-dark-gray;
  color: $light-white;
}

.c-form.c-form--dark {
  align-items: center;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 4px 8px;
  position: absolute;
  resize: none;
  white-space: pre-wrap;
  word-break: break-word;

  .c-form__submit {
    margin: unset;
  }

  .c-form__text {
    border: 1px solid $d1-mid-gray;
    height: unset;
    line-height: 1.4;
    margin: 4px 8px;
    padding: 2px 8px;

    &::placeholder {
      color: $l1-mid-gray;
    }

    &:focus {
      border: 1px solid $d1-purple;
      box-shadow: 0 0 2px $l2-purple;
    }

    &:first-of-type {
      // special spacing so sides around input appear even
      margin-left: 2px;
    }
  }
}

// sign in, sign up, and password reset forms

.c-miniwrap {
  align-items: center;
  margin: 60px auto 0;
  max-width: 600px;

  .c-miniwrap__logo {
    height: auto;
    margin-bottom: 20px;
    width: 400px;
  }

  .c-miniwrap__form {
    background-color: $light-white;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
    max-width: 400px;
    padding: 20px;

    .c-miniform__field {
      margin-bottom: 15px;

      &:last-of-type {
        margin-bottom: 25px;
      }
    }
  }
}
