
          @use '@scss/all' as wallaby;
        
// optional, context-dependent outer wrapper

.moveToFolder___tree {
  border: 1px solid wallaby.$l2-light-gray;
  height: 200px;
  min-width: 400px;
}
