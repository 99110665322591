.c-picker {
  width: 100%;

  .c-picker__instructions {
    color: $d2-dark-gray;
    font-family: $base-font-family; // prevents instructions from inheriting Courier

    .c-pickerinstructions__p {
      margin-bottom: 20px;

      &:last-of-type {
        margin-bottom: 40px;
      }
    }
  }

  .c-picker__type {
    align-items: center;
    border: 1px solid $base-light-gray;
    color: $d1-mid-gray;
    display: flex;
    padding: 20px;

    &:hover {
      border: 1px solid $d1-purple;
      color: $d2-dark-gray;
      cursor: pointer;
      outline: 1px solid $d1-purple;

      .c-pickerlabel__name {
        color: $d1-purple; // purple label on hover
      }
    }

    /* stylelint-disable */
    // disabling linting here because there's no quick way to undo the over-nesting here
    // TODO: retwrite and restructure this section of the doc type picker to be more modular
    &.c-picker__type--selected {
      border: 1px solid $d1-purple;
      color: $d2-dark-gray;
      outline: 1px solid $d1-purple;

      .c-pickertype__label {
        .c-pickerlabel__name {
          color: $d1-purple;
        }

        .c-pickerlabel__description {
          color: $d2-dark-gray;
        }
      }
    }

    .c-pickerlabel__description {
      font-family: $base-font-family; // prevents type descriptions from inheriting Courier
    }

    .c-pickertype__label {
      .c-pickerlabel__name {
        font-family: $heading-font-family;
        font-size: $s2-font-size;
        font-weight: $heading-font-weight;
        margin-bottom: 2px;
        text-transform: uppercase;
      }
    }
    /* stylelint-enable */
  }
}

// DOC TYPE ICONS

// uniform wrapper for matching text indent across doc types
// size of largest doc type icon
.c-pickertype__imgwrap {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 18px;
  margin-right: 15px;
  width: 20px;
}
