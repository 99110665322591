
          @use '@scss/all' as wallaby;
        
.column___rightAlign {
  text-align: right;
}

.column___readOnly {
  color: #898989;
}

.column___controls,
.column___snapstreamLink,
.column___snapstreamLink .ag-cell-wrapper {
  padding: 0 !important;
}
