
          @use '@scss/all' as wallaby;
        
$size: 60px;

.templateImage {
  background: wallaby.$theme-violet-1;
  border-radius: 5px;
  height: $size;
  min-width: $size;
  width: $size;

  .faIcon {
    font-size: calc($size / 1.6);
  }
}
