
          @use '@scss/all' as wallaby;
        
.toolbarTab {
  background-color: wallaby.$light-white;
  border-bottom: 3px solid transparent;
  border-radius: 0;
  color: wallaby.$theme-gray-7;
  font-size: 15px;
  font-weight: bold;
  height: 40px;
  padding: 0 16px;

  &:hover {
    background-color: wallaby.$light-white;
    border-bottom: 3px solid wallaby.$theme-violet-1;
    color: wallaby.$theme-dark-9;
  }
}

.toolbarTabsList {
  flex-wrap: nowrap;
}

.toolbarTab[data-active] {
  border-bottom: 3px solid var(--mantine-color-violet-7);
  color: wallaby.$theme-violet-7;
}
