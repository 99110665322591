
          @use '@scss/all' as wallaby;
        
/* stylelint-disable selector-max-compound-selectors */

:root {
  --zoom-level: 1;
}

.chooPage {
  // this position: relative is EXTREMELY important because of the fragile way
  // we calculate positions relative to the editor's offsetTop (e.g. comment icons
  // next to blocks.
  position: relative;
  width: 100%;

  // special way to hide the editor while still having it do all it's layout, etc.
  // if we try { display: none } we get errors with prosemirror positioning, etc
  &.safelyHidden :global(.c-editor) {
    opacity: 0;
    user-select: none;
  }
}

// this fills the entire area dedicated to the editor and does no management
// of scrolling. Any wrapper components are responsible for managing the
// scrolling (e.g. PrintPreviewWrapper)
.scriptAndToolbars {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

// we hide the contextual block type icon both when the editor is blurred and
// when users are interacting with the corresponding toolbar menu
.noFocus,
.elementMenuOpened {
  :global(.o-elementicon) {
    display: none;
  }
}

.disconnected :global {
  .ProseMirror {
    color: wallaby.$d1-mid-gray;
    cursor: default;

    // GROSS: we have to use !important to override script format styles
    // which use !important to override the style prop set by the
    // prose schema toDOM
    .o-block-element {
      color: wallaby.$d1-mid-gray !important;
    }
  }
}

// MARGIN SLOT POSITIONING
// TODO: move this into wombat-lite once we unify the printing/screen models
:global {
  .ProseMirror {
    [data-margin-slot] {
      position: absolute;
    }

    [data-margin-slot^='top-'] {
      top: 0.5in;
    }

    [data-margin-slot^='bottom-'] {
      top: 10.3in;
    }

    [data-margin-slot$='-left'] {
      left: 1.5in;
    }

    [data-margin-slot$='-right'] {
      right: 1in;
      text-align: right;
    }

    [data-margin-slot$='-center'] {
      left: 1.5in;
      right: 1in;
      text-align: center;
    }

    // The indentation styles plugin sets values for these for
    // ink scripts. This gives a hanging indent when wrapping ink text. If
    // the var isn't set (e.g. plugin doesn't run) then these set padding-left
    // and text-indent to 0.
    .o-block-element {
      padding-left: var(--wrap-indent, 0);
      text-indent: calc(-1 * var(--wrap-indent, 0));
    }
  }

  // Quick fixes for a problem with locked pages-- once we change the script configurations and rev
  // codex we should remove this.
  // NOTE: this short-term fix means SNL's headers disappear if they lock pages.
  .ProseMirror[data-locked='true'] [data-margin-slot='top-right'] {
    display: none;
  }

  // another locked page issue: We need the counter based page numbers for the
  // snapshot views-- they're done differently than in the live editor. Everywhere
  // else, however, we're supplying the proper page numbers and need to suppress this.

  // This should also move to the margin configs as well and get removed from here.
  .ProseMirror:not([data-locked='true']) .o-page::before {
    display: none;
  }
}
