// LAYOUT HELPERS

// positioning

.is-relative {
  position: relative;
}

.has-fixed-scroll {
  // for when you want to disable scrolling on a background component
  height: 100vh;
  overflow: hidden;
}

.is-full-width {
  width: 100%;
}

// spacing

.has-left-margin {
  margin-left: 10px;
}

// text-alignment

.is-left-aligned {
  text-align: left;
}

.is-right-aligned {
  text-align: right;
}

.is-center-aligned {
  text-align: center;
}

// STATUS HELPERS

// if we want to make this a generalized helper `!important` is necessary,
// because it's specifically designed to override default styles

.is-disabled {
  color: $d1-mid-gray !important;

  &:hover {
    background-color: $light-white !important;
    cursor: default !important;
  }
}

// COLOR HELPERS

.is-highlighted {
  background-color: $highlight-yellow;
}

// TEXT HELPERS

// font families

.is-script-text {
  font-family: $script-font-family;
  font-size: $script-font-size;
  line-height: $script-line-height;
}

// text colors

.is-ultralight {
  // for light text on dark bagrounds, e.g. comments
  color: $l1-mid-gray;
}

.has-light-text, // TODO: delete one
.is-light {
  color: $d1-mid-gray;
}

.is-medlight {
  color: $l2-dark-gray;
}

.is-code {
  // for keyboard shortcuts and code snippets
  background-color: $d1-light-gray;
  border-radius: 3px;
  font-family: $script-font-family;
  font-size: $s1-font-size;
  line-height: 16px;
  padding: 4px 6px 0;
}

// VISIBILITY

.is-invisible {
  visibility: hidden;
}

// TEXT TRUNCATION

.is-truncated {
  display: flex;
  /*
    in https://css-tricks.com/flexbox-truncated-text/ the flex-grow declaration below is set.
    in our app it causes .c-header to grow vertically when there isnt enough
    content below it to fill the page (because .c-app { min-height: 100vw })

    another option to fix would be .c-header { max-height }
  */
  // flex: 1;
  min-width: 0;

  .is-truncated__long {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
