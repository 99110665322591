// presence dots

.o-dot {
  // context-agnostic
  align-items: center;
  background-color: #000;
  border-radius: 50%;
  color: $light-white;
  display: flex;
  flex-direction: column;
  font-family: $heading-font-family;
  height: 40px;
  justify-content: center;
  left: -20px;

  // position must be absolute!
  // relies on being placed inside c-editor__gutterleft
  position: absolute;
  text-transform: lowercase;
  -webkit-user-select: none;
  user-select: none;
  width: 40px;
  z-index: map-get($zindex, 'editor-avatar');

  &.is-red {
    background-color: $red-user-color;
  }

  &.is-indigo {
    background-color: $indigo-user-color;
  }

  &.is-teal {
    background-color: $teal-user-color;
  }

  &.is-gold {
    background-color: $gold-user-color;
  }

  &.is-orange {
    background-color: $orange-user-color;
  }

  &.is-blue {
    background-color: $blue-user-color;
  }

  &.is-brown {
    background-color: $brown-user-color;
  }

  &.is-gray {
    background-color: $gray-user-color;
  }

  &.o-hovertooltip[data-tooltip]::after {
    top: -32px;
  }

  img {
    border-radius: 50%;
    height: 34px;
    object-fit: cover;
    width: 34px;
  }
}
