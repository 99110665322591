
          @use '@scss/all' as wallaby;
        
.toast {
  align-items: center;
  border-radius: 3px;
  display: flex;
  justify-content: space-between;
  padding: 5px;
}

.toast___neutral {
  background-color: wallaby.$note-light-color;
  color: wallaby.$note-color;

  .toast_action a,
  .toast_action a:visited {
    color: wallaby.$note-color;

    &:hover {
      color: darken(wallaby.$note-color, 10%);
    }
  }

  .toast_button:hover {
    color: darken(wallaby.$note-color, 10%);
  }
}

.toast___success {
  background-color: wallaby.$success-light-color;
  color: wallaby.$success-color;

  .toast_action a,
  .toast_action a:visited {
    color: wallaby.$success-color;

    &:hover {
      color: darken(wallaby.$success-color, 10%);
    }
  }

  .toast_button:hover {
    color: darken(wallaby.$success-color, 10%);
  }
}

.toast___warning {
  background-color: wallaby.$warning-light-color;
  color: wallaby.$warning-dark-color;

  .toast_action a,
  .toast_action a:visited {
    color: wallaby.$warning-dark-color;

    &:hover {
      color: darken(wallaby.$warning-dark-color, 10%);
    }
  }

  .toast_button:hover {
    color: darken(wallaby.$warning-dark-color, 10%);
  }
}

.toast___error {
  background-color: wallaby.$error-light-color;
  color: wallaby.$error-color;

  .toast_action a,
  .toast_action a:visited {
    color: wallaby.$error-color;

    &:hover {
      color: darken(wallaby.$error-color, 10%);
    }
  }

  .toast_button:hover {
    color: darken(wallaby.$error-dark-color, 10%);
  }
}

.toast_message {
  align-items: center;
  display: flex;
  padding: 5px;
}

.toast_icon {
  margin-right: 8px;
}

.toast_button {
  color: inherit;
}

.toast_action {
  align-items: center;
  display: flex;
  font-size: 14px;
  font-weight: bold;
  padding: 0 10px;
  text-transform: uppercase;

  a {
    text-decoration: underline;
  }
}
