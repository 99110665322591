
          @use '@scss/all' as wallaby;
        
// This positions the ... entire component on the right of the
// listing and centers the ... icon vertically
.wrapper {
  align-items: center;
  color: wallaby.$theme-gray-8;
  display: flex;
  height: 100%;
  opacity: 0.4;
  padding: 0 6px;
  right: 0;

  // on hover or if open, show at full opacity
  &[data-expanded],
  &:hover {
    opacity: 1;
  }
}
