
          @use '@scss/all' as wallaby;
        
.headerTimestamp_dropdown {
  white-space: normal;
}

.headerTimestamp_text {
  align-items: center;
  white-space: nowrap;
}
