
          @use '@scss/all' as wallaby;
        
.navLink {
  align-items: center; /* centers handle icon within link regardless of height */
  border: 0;
  color: wallaby.$d2-dark-gray;
  display: flex;
  flex-shrink: 0; /* prevent safari squish */
  font-family: wallaby.$script-font-family;
  font-size: wallaby.$s1-font-size;
  margin: 4px 0;
  position: relative;
  text-transform: uppercase;
  -webkit-user-select: none;
  user-select: none;

  &:hover {
    background-color: wallaby.$l4-purple;
    color: wallaby.$d2-dark-gray;
    cursor: pointer;
    text-decoration: none;
  }
}

.navLink___blank {
  color: wallaby.$l1-mid-gray;
  font-style: italic;
  font-weight: unset;
}

.navLink___top {
  font-weight: bold;
}

.navLink___bracket {
  color: wallaby.$bracket-color;
  padding: 0;
  position: relative;
  text-transform: uppercase;

  &:hover {
    color: wallaby.$bracket-color; // navLink hover override
  }
}

.navLink___bracket .navLink_element {
  /* truncates brackets with `...` */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.navLink_dropTarget {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  transform: translateY(-50%);
  width: 100%;
}

.navLink_dropTarget___below {
  transform: translateY(50%);
}

.navLink_dropTarget___over .navLink_dropTargetLine {
  background-color: wallaby.$d1-purple;
  height: 4px;
  width: 100%;
}

.navLink_icon i {
  color: wallaby.$light-white;
  font-family: 'icomoon';
  font-size: wallaby.$s3-font-size;
  font-style: normal;
  left: 6px;
  opacity: 0.5;
  padding-bottom: 2px;
  position: absolute;
  top: 4px;
  width: 8px;
}

.navLink .navLink_icon i {
  color: wallaby.$d2-dark-gray;
  visibility: hidden;
}

.navLink:hover .navLink_icon i {
  cursor: move;
  visibility: visible;
}

.navLink_element {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  margin-left: 16px;
  overflow: hidden;
  padding: 4px 0 0 8px; /* visually corrects for Courier Prime's weird line height so the drag-and-drop icon appears centered */
  text-overflow: ellipsis;
  white-space: unset !important; /* overriding nowrap from _script-nav.scss */
}

.navLink_cursor {
  left: 5px;
  opacity: 0;
  position: absolute;
}

.navLink_cursor {
  &:global(.navLink_cursor___on),
  &:global(.navLink_cursor___above),
  &:global(.navLink_cursor___below) {
    opacity: 1;
  }

  &:global(.navLink_cursor___above),
  &:global(.navLink_cursor___below) {
    transform: translateY(50%);
  }
}

.navLink:hover :global(.navLink_cursor___on) {
  display: none;
}
