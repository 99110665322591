
          @use '@scss/all' as wallaby;
        
/* stylelint-disable selector-max-compound-selectors */

:root {
  --zoom-level: 1;
}

// this fills the entire area dedicated to the editor and does no management
// of scrolling. Any wrapper components are responsible for managing the
// scrolling (e.g. PrintPreviewWrapper)
.scriptAndToolbars {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}
