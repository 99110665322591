
          @use '@scss/all' as wallaby;
        
.slugTiming {
  margin-right: 5px;
}

.slugTiming_scroll {
  background-color: wallaby.$theme-gray-0;
  // overwritten with a dynamic calculation
  max-height: 60vh;
  overflow: auto;
}

.slugTiming_stack {
  border-top: 1px solid wallaby.$theme-gray-3;
  padding-top: 10px;
}

.slugTiming_item___total {
  border-bottom: 0;
  color: wallaby.$theme-dark-9;
}

.slugTiming_item___blank {
  color: wallaby.$theme-gray-5;
  font-style: italic;
}
