
          @use '@scss/all' as wallaby;
        
.customIcon {
  text-align: center;
}

.icomoonIcon {
  font-family: 'icomoon';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}
