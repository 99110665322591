
          @use '@scss/all' as wallaby;
        
.documentTitle {
  border: 1px solid transparent;
  border-radius: 3px;
  cursor: text;
  display: flex;
  flex-direction: row;
  margin-left: 0;
  margin-right: 5px;
  padding: 4px;

  // maintain hover border on focus
  // focus-within not yet supported in Edge but whatever
  &:hover,
  &:focus-within {
    border: 1px solid wallaby.$theme-violet-5;
  }
}

/* stylelint-disable */
// needs to specify input type for the cascade to work
input[type='text'].documentTitle_input {
  border: 1px solid transparent !important;
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  height: auto;
  margin: 0;
  // replace input[type="text"] defaults
  min-width: 25px;
  // safari bug stopgap fix
  padding: 0;
  text-overflow: ellipsis;
  // reset to auto -- calculated on the fly in JS
  // NOTE: 100% messes with width of siblings
  width: auto;

  &:focus {
    border: 1px solid transparent !important;
    box-shadow: none !important;
  }
}
/* stylelint-enable */

.documentTitle__iconwrap {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.documentTitle_icon {
  font-size: 15px;
  margin-right: 4px;
  shape-rendering: crispedges;
}

.documentTitle_divider {
  color: wallaby.$theme-gray-5;
  margin: 0 10px;
}
