
          @use '@scss/all' as wallaby;
        
.title {
  font-family: 'Lato', sans-serif;
  font-weight: bold;
}

.separator {
  color: wallaby.$theme-gray-5;
  margin: 0 10px;
}
