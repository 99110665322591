// KEYBOARD SHORTCUTS
// modal and tables as opened from the header

// modal overrides

.c-modal.c-modal--shortcuts {
  max-width: unset;

  .c-modal__top,
  .c-modal__content {
    max-width: unset;
    overflow-y: auto;
  }

  // in a flex layout with space-between + max-width for the container would be enough on its own to control spacing
  // we arent setting max-width because in Safari we display one column instead of two
  .c-modal__content > .l-box {
    padding: 0 10px;
  }
}

// keyboard shortcut tables

.c-shortcuts {
  width: 300px;

  .c-shortcuts__label {
    max-width: 200px;
  }

  .c-shortcuts__item {
    border: 1px solid $d1-light-gray;
    border-bottom: 0;
    display: flex;
    justify-content: space-between;
    padding: 5px;

    &:last-of-type {
      border-bottom: 1px solid $d1-light-gray;
      margin-bottom: 20px;
    }

    &.c-shortcuts__item--heading {
      background-color: $l3-light-gray;
      color: $l2-dark-gray;
      font-family: $heading-font-family;
      font-size: $s2-font-size;
      font-weight: $heading-font-weight;
      text-transform: uppercase;
    }
  }
}
