
          @use '@scss/all' as wallaby;
        
.wrapper {
  width: fit-content;
}

@mixin hyperlinkaction {
  color: wallaby.$theme-dark-9;

  &:hover {
    background-color: wallaby.$theme-gray-2;
  }
}

.hyperlinkEditor_action {
  @include hyperlinkaction;
}

.hyperlinkEditor_action___danger {
  @include hyperlinkaction;
  color: wallaby.$theme-red-6;
}
