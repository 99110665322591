
          @use '@scss/all' as wallaby;
        
/*

  customize the AgGrid theme per this technique:
  https://www.ag-grid.com/react-data-grid/global-style-customisation-sass/

*/

$active-color: wallaby.$theme-violet-5;
$selection-background-color: rgba($active-color, 0.1);

@use '~@ag-grid-community/styles' as ag;
@include ag.grid-styles(
  (
    theme: ag-theme-alpine,
    alpine-active-color: $active-color,
    odd-row-background-color: #ffff,
    border-color: #e4e4e4,
    border-radius: 0,
    cell-horizontal-border: solid #e4e4e4,
    row-hover-color: transparent,
    cell-horizontal-padding: 10px,
    checkbox-checked-color: $active-color,
    control-panel-background-color: #fff,
    data-color: #0d0d13,
    font-family: (
      'Lato',
      sans-serif,
    ),
    font-size: 14px,
    header-background-color: #f2f2f2,
    header-column-separator-height: 100%,
    header-column-separator-width: 1px,
    selected-row-background-color: $selection-background-color,
  )
);

.ag-theme-alpine {
  .ag-root-wrapper {
    border-bottom: 0;
    border-top: 0;
  }

  .ag-cell-focus {
    border-color: $active-color !important;
    border-width: 1px;
  }

  // this sets the color of our drag-drop targets (overriding the
  // transparent value we used above)
  .ag-row-highlight-above .ag-cell {
    border-top-color: $active-color;
  }

  .ag-row-highlight-below .ag-cell {
    border-bottom-color: $active-color;
  }

  .ag-row-hover::before {
    background-color: $selection-background-color;
  }

  .ag-menu-option-active {
    background-color: #f2f2f2;
  }

  .ag-menu-option.danger-operation {
    color: wallaby.$error-color;
  }

  // column headers
  .ag-header-cell:hover {
    background-color: wallaby.$l2-light-gray;
    cursor: grab;
  }

  // pinned left column header
  .ag-pinned-left-header .ag-header-cell:hover {
    background-color: unset;
    cursor: default;
  }

  .ag-header-cell-resize::after {
    background-color: var(
      --ag-header-column-resize-handle-color,
      rgba(228, 228, 228, 1)
    );
    height: 100%;
    top: 0;
  }

  .ag-header-cell-resize:hover::after {
    background-color: #8e66c8;
    outline: 1px solid #8e66c8;
  }

  // drag icon
  .ag-cell .ag-icon-grip {
    opacity: 0.3;
  }

  .ag-cell:hover .ag-icon-grip {
    opacity: 1;
  }

  //checkbox
  .ag-checkbox:hover {
    cursor: pointer;
  }

  // ag-grid does idiotic text wrapping (they break words in the middle for no reason)
  // this changes to a sane setting-- we only break a word if it can't fit into one
  // line of a cell
  .ag-cell-wrap-text {
    overflow-wrap: break-word;
    white-space: pre-line;
    word-break: normal;
  }
}

/*

  Row heights, cell padding, line-heights, etc. These are user-configurable

*/
// we need these to be defined on root to be able to apply
// them separately to print headers/footers since they're not
// within the ag-grid any more
:root {
  --standard-h-padding: 8px;
  --compact-h-padding: 4px;
  --loose-h-padding: 8px;
  --compact-font-size: 13px;
}

// Row density
.ag-theme-alpine {
  --ag-row-height: 42px;
  --ag-header-height: 42px;
  --ag-cell-vertical-padding: 8px;
  --ag-cell-horizontal-padding: var(--loose-h-padding);
  --x-line-height: 1.4;

  .ag-cell {
    align-items: flex-start;
    line-height: var(--x-line-height);
  }

  .ag-cell-wrapper {
    align-items: flex-start;
    min-height: calc(100% - (2 * var(--ag-cell-vertical-padding)));
  }

  .ag-cell:not(.ag-cell-inline-editing) {
    padding-bottom: var(--ag-cell-vertical-padding);
    padding-top: var(--ag-cell-vertical-padding);
  }
}

.ag-theme-alpine.compact {
  --ag-row-height: 24px;
  --ag-font-size: var(--compact-font-size);
  --ag-header-height: 28px;
  --ag-cell-vertical-padding: 2px;
  --ag-cell-horizontal-padding: var(--compact-h-padding);
  --x-line-height: 1.1;
}

.ag-theme-alpine.standard {
  --ag-row-height: 34px;
  --ag-header-height: 34px;
  --ag-cell-vertical-padding: 6px;
  --ag-cell-horizontal-padding: var(--standard-h-padding);
  --x-line-height: 1.2;
}

// cell padding inside the header rows
@media print {
  .rundown-header .ag-header-cell {
    padding-left: var(--standard-h-padding);
  }

  .rundown-header.compact .ag-header-cell {
    font-size: var(--compact-font-size);
    padding-left: var(--compact-h-padding);
  }

  .rundown-header.loose .ag-header-cell {
    padding-left: var(--loose-h-padding);
  }
}
