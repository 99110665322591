
          @use '@scss/all' as wallaby;
        
.dragHandle {
  background-color: wallaby.$theme-gray-3;
  box-sizing: border-box;
  position: absolute;
  // this keeps the drag handle above the resizable contents, important when
  // making the resizable child small
  z-index: 10;

  &.horizontal {
    bottom: 0;
    height: var(--grab-handle-size);
    left: 0;
    width: 100%;
  }

  &.vertical {
    height: 100%;
    right: 0;
    top: 0;
    width: var(--grab-handle-size);
  }
}

.dragHandle:not(.disabled) {
  &:hover,
  &.dragging {
    background-color: wallaby.$theme-violet-6;
  }

  &.horizontal {
    cursor: row-resize;
  }

  &.vertical {
    cursor: col-resize;
  }
}
