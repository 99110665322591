
          @use '@scss/all' as wallaby;
        
.sortMenuButton {
  border-radius: 3px;
  color: wallaby.$theme-gray-9;
  padding: 4px 6px;
  width: 100%;

  &:hover {
    background-color: wallaby.$theme-gray-3;
  }
}

.labelWrapper {
  overflow: hidden;
  text-overflow: ellipsis;
}
