
          @use '@scss/all' as wallaby;
        
.sideSelector {
  border: 1px wallaby.$theme-gray-4 solid;
}

.sideTitle {
  background-color: wallaby.$theme-gray-0;
  border-bottom: 1px wallaby.$theme-gray-4 solid;
  font-family: 'Lato', sans-serif;
  font-size: 13px;
  font-weight: 700;
  height: 35px;
  line-height: 35px;
  padding-left: 10px;
  text-transform: uppercase;
}

.sideRadio {
  font-size: 16px;
}

.fullDocument {
  font-weight: 700;
  margin: 15px 0;
  padding-left: 11px;
}

.divider {
  margin: 0 20px;
}

.sleneList_scroller {
  height: 250px;
  padding-top: 10px;
  width: 260px;
}

.sleneList {
  padding: 10px;
}
