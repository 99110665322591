//
// COLLABORATOR LIST //
//

// layout for the collab list as it appears in the toolbar

.c-collaborators.c-collaborators--toolbar {
  margin: auto 5px;
  position: relative;
}

// collab list and dropdown component

.c-collaborators {
  align-items: center;
  display: flex;
  flex-direction: row;

  &.c-collaborators--expanded {
    @media screen and (max-width: 1620px) {
      display: none;
    }
  }

  &.c-collaborators--compact {
    display: none;

    @media screen and (max-width: 1620px) {
      display: flex;
    }

    @media screen and (max-width: 1420px) {
      display: none;
    }
  }

  &.c-collaborators--collapsed {
    display: none;

    @media screen and (max-width: 1420px) {
      display: flex;
    }
  }
}

.c-collaborators__container {
  align-items: center;
  display: flex;
  flex-direction: column;

  &:not(:first-of-type) {
    margin-left: -7px;
  }

  &.o-hovertooltip[data-tooltip] {
    &::after {
      // specific positioning for avatar tooltips
      top: 40px;
    }
  }
}

.c-collaborators__link {
  font-size: 13px;
  margin-left: 5px;
  white-space: nowrap;
}

.c-collabcontainer__avatar {
  cursor: pointer;
  height: 32px;
  width: 32px;
}

.c-collaborators__overflow {
  color: $l1-dark-gray;
  font-size: $s1-font-size;
  margin-left: 8px;
}

.c-collabover__label {
  color: $d3-purple;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.c-collabover__list {
  background-color: $d1-dark-gray;
  border-radius: 3px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.6);
  color: $light-white;
  display: none;
  flex-direction: column;
  font-size: $s2-font-size;
  left: -60%;
  max-height: 80vh;
  max-width: 150px;
  overflow: auto;
  padding: 5px;
  position: absolute;
  top: 20px;

  &.is-open {
    display: flex;
    min-width: 180px;
  }
}

.c-collaboverlist__item {
  align-items: center;
  display: grid;
  grid-template-columns: 28px auto;
  margin: 0;
  overflow-wrap: break-word;
  padding: 4px 2px;

  &:hover {
    cursor: pointer;
  }
}

.c-collabitem__avatar {
  height: 22px;
  width: 22px;
}

// styles for the toolbar instance of the collaborator list
// TODO: remove the `--toolbar` modifier once implemented

.c-collaborators--toolbar {
  .c-collaborators__container .c-collabcontainer__avatar {
    // overrides nested styles above
    height: 28px;
    width: 28px;
  }
}
