
          @use '@scss/all' as wallaby;
        
@mixin base {
  margin: 0 10px 0 0;
}

// show switcher

.switcher {
  @include base;
}

// button with the current workspace's name as its label with sort icon

.switcher_current {
  align-items: center;
  background-color: wallaby.$light-white;
  // overrides both showrunner and skeleton's semantic button styles
  border: 1px wallaby.$theme-gray-3 solid;
  border-radius: 3px;
  color: wallaby.$theme-dark-9;
  display: flex;
  font-family: wallaby.$base-font-family;
  font-size: wallaby.$s1-font-size;
  height: 40px; // to match search bar
  letter-spacing: 0;
  margin-bottom: 0;
  min-width: unset; // mantine button override
  padding: 4px 10px;
  text-transform: none;
  white-space: nowrap;

  &:hover {
    background-color: wallaby.$theme-gray-1;
    cursor: pointer;
  }
}

// sort icon inside the button

.switcher_icon {
  margin-left: 5px;
}

.switcher_current:hover .switcher_icon {
  color: wallaby.$theme-dark-9;
}

// workspace list

.switcher_list {
  border: 1px solid wallaby.$d1-light-gray;
  border-radius: 3px;
  box-shadow: 0 0 10px rgb(171 171 171 / 0.25);
  display: block;
  font-size: wallaby.$s1-font-size;
  max-height: 50vh; // ~6 orgs max
  min-width: 180px;
  overflow-y: auto;
  padding: 0.25rem; // to match FancyMenu component
  position: absolute;
  top: 35px;
}

// individual workspace list items

.switcher_workspace {
  align-items: center;
  background-color: wallaby.$light-white;
  display: flex;
  margin: 0;
  padding: 6px;

  &:hover {
    background-color: wallaby.$l3-light-gray;
    cursor: pointer;
  }

  &:first-of-type {
    padding-top: 8px;
  }

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 8px;
  }

  // The wombat styles are set up to trigger on the :hover state.  We want to highlight the selected item
  // when using the keyboard as well
  &.selected {
    background-color: wallaby.$theme-violet-5;
    color: wallaby.$light-white;
    // normal mouse hover
    &:hover,
  // this is the selector mantine applies when using the arrow keys
  &[data-hovered='true'] {
      background-color: wallaby.$theme-violet-7;
    }
  }
}
