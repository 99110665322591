// Empty block placeholders
// --------------------
// The editor adds .is-empty-block and .is-empty-doc classes to blocks
// as appropriate.
//
// RULES
// 1. When the doc is completely empty, we show placeholder text for each block type
//
// 2. When the doc is completely empty and the block type is one of the default block types
//    (dialogue, character or sceneHeading) we append in some extra text to explain about changing
//    block types
//
// 3. When there is an empty block in a non-empty doc and the block type is new_act,
//    end_of_act, sceneHeading or slug, we we use a placeholder

@mixin placeholder-content(
  $block-name,
  $show-menu-hint: false,
  $show-in-non-empty-doc: false
) {
  // placeholder for an empty doc, if $show-menu-hint is true
  // add the extra text
  &.is-empty-doc.is-empty-block::before {
    @if $show-menu-hint {
      content: $block-name ' (Click icon or TAB to change)';
    } @else {
      content: $block-name;
    }
  }

  // placeholder for an empty block in a non-empty doc, only
  // triggerd if $show-in-non-empty-doc is true
  @if $show-in-non-empty-doc {
    &:not(.is-empty-doc).is-empty-block::before {
      content: $block-name;
    }
  }
}

@media screen {
  // SHARED styles for all placeholders
  .o-block-element.is-empty-block {
    // set color on the parent element so that underlines get picked up, etc.
    color: #aaa !important;

    &::before {
      color: #aaa !important;
      left: 0;
      position: absolute;
      right: 0;
      text-decoration: inherit;
    }
  }

  .o-block-element.o-new-act {
    @include placeholder-content('New Act', false, true);
  }

  .o-block-element.o-end-of-act {
    @include placeholder-content('End of Act', false, true);
  }

  .o-block-element.o-general {
    @include placeholder-content('General Text');
  }

  .o-block-element.o-bracket {
    @include placeholder-content('[Bracket]');
  }

  .o-block-element.o-character {
    @include placeholder-content('Speaker', true, false);
  }

  .o-block-element.o-parenthetical {
    @include placeholder-content('(Parenthetical)');
  }

  .o-block-element.o-dialogue {
    @include placeholder-content('Dialogue', true, false);
  }

  .o-block-element.o-transition {
    @include placeholder-content('Transition');
  }

  .o-block-element.o-scene {
    @include placeholder-content('Scene Heading', true, true);
  }

  .o-block-element.o-action {
    @include placeholder-content('Action');
  }

  .o-block-element.o-slug {
    @include placeholder-content('Slug', false, true);
  }
}
