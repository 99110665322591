.l-wrapper {
  &.l-wrapper--fullvh {
    height: 100%;
    overflow: hidden;
  }

  &.l-wrapper--fullvw {
    overflow: hidden;
    width: 100%;
  }

  &.l-wrapper--absolute {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }

  &.l-wrapper--scrolling {
    height: 100%;
    overflow: auto;
    width: 100%;
  }

  // flex variations

  &.l-wrapper--flex-row {
    display: flex;
    flex-direction: row;
  }

  &.l-wrapper--flex-col {
    display: flex;
    flex-direction: column;
  }

  &.l-wrapper--align-content-center {
    align-content: center;
  }

  &.l-wrapper--align-items-center {
    align-items: center;
  }

  &.l-wrapper--justify-end {
    display: flex;
    justify-content: flex-end;
  }

  &.l-wrapper--justify-center {
    justify-content: center;
  }

  &.l-wrapper--justify-between {
    justify-content: space-between;
  }
}
