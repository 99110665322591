
          @use '@scss/all' as wallaby;
        
// to better match the other toolbar buttons
.extras_button {
  color: wallaby.$theme-dark-9;

  &:hover {
    background-color: wallaby.$theme-gray-2;
  }
}

.extras_dropdown {
  overflow-y: auto;
}
