// PAGINATION
// TODO: remove this file when snapshot history has moved out of
// the editor toolbar and safely into the sidebar

.c-pagination {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
}

.c-pagination__link {
  align-items: center;
  display: flex;

  &:hover {
    text-decoration: none;
  }
}

.c-pagination__link:hover .c-pagination__label {
  text-decoration: underline;
}

.c-pagination__link--first {
  visibility: hidden;
}

.c-pagination__icon {
  font-size: 12px;
  margin-left: 5px;
  padding: 7px 0 5px;
}

.c-pagination__icon:first-of-type {
  margin-left: unset;
  margin-right: 5px;
}

// pagination inside a bordered box

.c-pagination.c-pagination--box {
  background-color: $l4-light-gray;
  border: 2px solid $l2-light-gray;
  margin: 0;
  padding: 10px;
}
