
          @use '@scss/all' as wallaby;
        
.controls {
  padding-left: 5px;
}

.controls_hoverItem {
  opacity: 0;
}

.controls_dragHandle {
  &:hover {
    cursor: grab;
  }
}

:global(.ag-row-hover) .controls_hoverItem {
  opacity: 1;
}

:global(.ag-cell-focus) .controls_hoverItem {
  opacity: 1;
}
