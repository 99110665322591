
          @use '@scss/all' as wallaby;
        
.docLocator_button {
  background-color: wallaby.$light-white;
  border-radius: 3px;

  i {
    color: wallaby.$theme-gray-9;
  }

  &:hover {
    background-color: wallaby.$theme-gray-2;
  }
}
