
          @use '@scss/all' as wallaby;
        
$title-height: 38px;

.explorerView {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  &.explorerView___hidden {
    display: none;
  }
}

.explorerView_contents {
  flex-basis: 0;
  overflow: hidden;
  transition: flex-basis 0.15s ease-out;

  &.expanded {
    flex-basis: 100%;
  }
}

.explorerHeader {
  background-color: wallaby.$theme-gray-2;
  flex: 0 0 $title-height;
  padding: 0 5px; // breathing room between left edge and chevron button, as well as right edge
}

// wraps the expand chevron and the title
.explorerHeader_titleWrapper {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.explorerHeader_title {
  overflow: hidden;
  padding-left: 5px; // breathing room between left edge and title
  text-overflow: ellipsis;
  white-space: nowrap;
}

.expandIcon_wrapper {
  padding-left: 5px;
}

.expandIcon_btn:hover {
  background-color: wallaby.$theme-gray-3;
}
