
          @use '@scss/all' as wallaby;
        
.iconWrapper {
  position: relative;
}

.syncSpinner {
  bottom: 5px;
  left: 6px;
  position: absolute;
}
