/*
styles for numbering inputs (elements & pages)

NOTES:
- .e-page-number & .e-element-number-input top property set by JS
*/

/*
the following styles break a lint rule (`selector-no-qualifying-type`), but since they'd be difficult and time-consuming to fix, we've made the conscious decision to leave them be
*/

/* stylelint-disable */

/* all three elements need same font properties */
.e-page-number,
input[type='text'].e-page-number-input,
input[type='text'].e-element-number-input {
  font-family: 'Courier Prime', courier, monospace;
  font-size: 12pt;
  line-height: 1;
  text-align: right;
}

/*
wrapper for page number inputs.
also contains `.` (inputs can't have pseudo-elements).
*/
.e-page-number {
  position: absolute;
  right: 1in;
  white-space: nowrap;
  z-index: 10;
}

/* props shared by both inputs */
input[type='text'].e-page-number-input,
input[type='text'].e-element-number-input {
  background: transparent;
  border: 0;
  height: 1em;
  min-width: 1em;
  padding: 0;
}

/* element number props */
input[type='text'].e-element-number-input {
  position: absolute;
  right: 65px;
  z-index: 10;
}
/* stylelint-enable */
