
          @use '@scss/all' as wallaby;
        
.scriptStatus_button {
  font-size: 12px;
  font-weight: bold;
}

.scriptStatus_button__open {
  &:hover {
    background-color: wallaby.$theme-green-1;
  }
}

.scriptStatus_button__limited {
  &:hover {
    background-color: wallaby.$theme-orange-1;
  }
}

.scriptStatus_button__private {
  &:hover {
    background-color: wallaby.$theme-blue-1;
  }
}
