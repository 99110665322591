// DIVIDERS

// vertical rules used to separate groups of content,
// like in the toolbar and version subheader

.o-divider {
  align-self: stretch;
  background-color: #b5b5b5;
  margin: 6px 8px;
  width: 1px;

  &.o-divider--tall {
    margin: 0 8px;
  }

  &.o-divider--dark {
    // to use on dark backgrounds
    background-color: $base-blue-gray;
  }
}
