
          @use '@scss/all' as wallaby;
        
.printPreferences_modal {
  position: relative;
}

.printPreferences_modalBody {
  height: 80vh;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.printPreferences_content {
  display: flex;
  inset: 20px;
  overflow: hidden;
  position: absolute;
}

.leftColumn {
  overflow: auto;
  padding-right: 20px;
}

.printPreferences_columnVizScroller {
  background-color: wallaby.$light-white;
  border: 1px wallaby.$theme-gray-4 solid;
  border-radius: 4px;
}

.printPreferences_columnVizScroller__content {
  padding: 10px;
  width: 200px;
}

.printPreferences_pageBreakDemo {
  border-top: 3px wallaby.$theme-gray-8 dashed;
  margin-top: 10px;
  width: 200px;
}

.printPreferences_previewWrapper {
  flex: 1;
  overflow-y: auto;
  position: relative;

  // position the grid in the preview pane
  :global(.printable-rundown-grid) {
    position: absolute;
  }

  :global {
    .ag-header-container {
      background-color: #fff;
    }
  }
}

.rightColumn {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.guideAndGrid {
  background-color: wallaby.$theme-gray-2;
  border: 1px wallaby.$theme-gray-4 solid;
  overflow: auto;
  padding: 30px;
}

.sizingGuide {
  border: 3px wallaby.$theme-green-8 dotted;
  border-bottom: 0;
  margin-bottom: -38px;
  padding-bottom: 48px;
  padding-top: 8px;
  position: relative;
}

.pageBreakInfo {
  padding: 10px 30px;
}

.hintText {
  color: wallaby.$theme-gray-6;
  font-size: 14px;
  font-weight: bold;
  text-transform: uppercase;
}
