
          @use '@scss/all' as wallaby;
        
.scrollArea {
  border: 1px wallaby.$theme-gray-4 solid;
  border-radius: 3px;
}

.scrollArea_contents {
  padding: 10px;
}
