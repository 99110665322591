
          @use '@scss/all' as wallaby;
        
$size: 18px;

.checkbox {
  background-color: #fff;
  border: 1px wallaby.$theme-gray-5 solid;
  border-radius: 3px;
  height: $size;
  margin-top: 3px;
  text-align: center;
  width: $size;
}

.checked {
  background-color: wallaby.$theme-violet-6;
  border: 0;
}

.checkmark {
  color: #fff;
}

.readOnly {
  pointer-events: none;
}
