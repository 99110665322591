
          @use '@scss/all' as wallaby;
        
.statusBar_button {
  align-items: center;
  background-color: wallaby.$light-white; // mantine Button override
  color: wallaby.$theme-gray-6; // mantine Button override
  display: flex;
  font-size: 14px;
  height: 1.5em; // to match ActionIcon
  min-width: unset; // mantine Button override
  padding-left: 4px; // mantine Button override
  padding-right: 4px; // mantine Button override

  &:hover {
    background-color: wallaby.$theme-gray-2; // override
    color: wallaby.$theme-dark-9; // override
  }
}

.statusBar_button:first-of-type:not(:last-of-type) {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.statusBar_button:not(:first-of-type) {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.statusBar_button___active {
  background-color: wallaby.$theme-violet-0;
  color: wallaby.$theme-violet-6;

  &:hover {
    background-color: wallaby.$theme-violet-0;
    color: wallaby.$theme-violet-6;
  }
}
