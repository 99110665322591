// rich text inline menu

.o-textmenu {
  align-items: center;
  background-color: $d1-dark-gray;
  border-radius: 3px;
  color: $light-white;
  display: flex;
  flex-direction: row;
  font-size: $s1-font-size;
  justify-content: flex-start;
  position: absolute;
  z-index: map-get($zindex, 'editor-ui');

  .o-textmenu__tooltip.c-tooltip[data-tooltip]::after {
    top: -30px;
  }

  .o-textmenu__button {
    background-color: $d1-dark-gray; // `button` override
    border: 0; // `button` override
    border-right: 1px solid $l1-dark-gray;
    color: $light-white;
    padding: 6px 8px;

    &:hover {
      background-color: $base-blue-gray;
    }

    &:first-of-type {
      border-left: 1px solid $l1-dark-gray;
      border-radius: 3px 0 0 3px; // rounds outer edges of first button in row
    }

    &:last-of-type {
      border-radius: 0 3px 3px 0; // rounds outer edges of last button in row
    }
  }

  .o-textmenu__item {
    padding: 0 8px;
  }

  .o-textmenu__link {
    color: $l2-purple;
  }

  .o-textmenu__submenu {
    align-items: center;
    border-right: 1px solid $l1-dark-gray;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 6px;

    &:last-of-type {
      border-right: 0;
    }
  }

  .o-textrich__item {
    padding: 2px 6px;

    &:hover,
    &.is-selected {
      background-color: $base-blue-gray;
      border-radius: 3px;
      cursor: pointer;
    }
  }

  .o-textitem__icon {
    // for icons with labels, e.g. `comment`
    margin-right: 3px;
    width: 11px;
  }

  .o-textitem__custom {
    // for icons that use our custom font
    // must be applied at `<i>` level
    font-family: 'icomoon';
    font-style: normal;
    font-weight: normal;
  }
}
