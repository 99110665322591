.l-spinnerwrapper {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.o-spinner {
  height: 30px;
  margin: auto 15px;
  width: 30px;

  @include keyframes(spinnerDelay) {
    0%,
    70%,
    100% {
      transform: scale3D(1, 1, 1);
      transform: scale3D(1, 1, 1);
    }

    35% {
      transform: scale3D(0, 0, 1);
      transform: scale3D(0, 0, 1);
    }
  }

  .o-spinner__cube {
    animation: spinnerDelay 1.3s infinite ease-in-out;
    float: left;
    height: 10px;
    width: 10px;
  }

  .is-cube-1 {
    animation-delay: 0.2s;
    background-color: $l4-purple;
  }

  .is-cube-2 {
    animation-delay: 0.3s;
    background-color: $l3-purple;
  }

  .is-cube-3 {
    animation-delay: 0.4s;
    background-color: $l2-purple;
  }

  .is-cube-4 {
    animation-delay: 0.1s;
    background-color: $l1-purple;
  }

  .is-cube-5 {
    animation-delay: 0.2s;
    background-color: $base-purple;
  }

  .is-cube-6 {
    animation-delay: 0.3s;
    background-color: $d1-purple;
  }

  .is-cube-7 {
    animation-delay: 0s;
    background-color: $d2-purple;
  }

  .is-cube-8 {
    animation-delay: 0.1s;
    background-color: $d3-purple;
  }

  .is-cube-9 {
    animation-delay: 0.2s;
    background-color: $d4-purple;
  }
}

// INLINE LOADING DOTS

@keyframes dot-keyframes {
  0% {
    opacity: 0.33;
    transform: scale(1, 1);
  }

  20% {
    opacity: 0.66;
    transform: scale(1, 1);
  }

  40% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  60% {
    opacity: 1;
    transform: scale(1.2, 1.2);
  }

  80% {
    opacity: 0.66;
    transform: scale(1.2, 1.2);
  }

  100% {
    opacity: 0.33;
    transform: scale(1, 1);
  }
}
