
          @use '@scss/all' as wallaby;
        
@import './variables';
@import './generic-layouts';

html,
body,
#root {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  width: 100%;
}

.l-app-with-header {
  @extend .l-wrapper;
  @extend .l-wrapper--fullvh;
  @extend .l-wrapper--fullvw;
  @extend .l-wrapper--flex-col;
}
