
          @use '@scss/all' as wallaby;
        
// we make an exception for 'T' in our lint rules even though its non-standard BEM
:global {
  .Toastify__toast-container {
    background-color: transparent;
    margin: 0;
    padding: 0;
  }

  // this insets the toast so that it starts 5px to the right of the folder
  // browser area's button panel. The width is controlled by a css var in
  // SidebarAndBody.tsx
  .Toastify__toast-container--bottom-left {
    left: 55px;
    min-width: 240px;
  }

  .Toastify__toast {
    background-color: transparent;
    box-shadow: none;
    font-family: unset;
    margin: 0;
    padding: 0;
  }

  .Toastify__toast-body {
    background-color: transparent;
    margin: 0;
    padding: 0;
  }
}
