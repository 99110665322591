
          @use '@scss/all' as wallaby;
        
.scriptScrollWrapper {
  display: flex;
  height: 100%;
  overflow-x: auto;
  overflow-y: scroll;
  position: relative;
  width: 100%;
}

.scriptCenteringBox {
  height: fit-content;
  min-width: fit-content;
  // This is important to make sure that remote avatars don't get cut off on narrow
  // screens. We could do it just on the left but then it's not centered. ¯\_(ツ)_/¯
  padding: 25px;
  transform: scale(var(--zoom-level));
  transform-origin: top left;
}
