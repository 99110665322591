
          @use '@scss/all' as wallaby;
        
// this needs extra help to play nicely with Mantine in the context of toasts

.toast_button___neutral {
  border-color: wallaby.$note-color;
  color: wallaby.$note-color;

  &:hover {
    background-color: darken(wallaby.$note-light-color, 5%);
  }
}
