
          @use '@scss/all' as wallaby;
        
.dateInput {
  line-height: 1.5;
}

.timeInput {
  line-height: 1.9;
}
