
          @use '@scss/all' as wallaby;
        
.snapshot {
  background-color: #f2f2f2;
  border-left: 3px solid #c1c1c1;
  display: flex;
  justify-content: space-between;
  margin: 4px 8px;

  &:hover {
    background-color: #f9f9f9;
    border-left: 3px solid #a27fd3;

    :global(.c-popup__button) {
      opacity: 0.4;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.snapshot___mini {
  @extend .snapshot;
  background-color: wallaby.$light-white;

  .snapshot_name {
    border: 0;
  }
}

.snapshot___elevated {
  @extend .snapshot;
}

.snapshot_link {
  display: flex;
  margin-bottom: 0;
  padding: 4px 8px;
  text-decoration: none;
  width: 100%;

  &:hover {
    text-decoration: none;
  }
}

.snapshot_title {
  @include wallaby.overflow-hidden;
  border: 1px solid transparent; // to visually match input state
  color: wallaby.$d2-dark-gray;
  padding: 1px 0 1px 4px; // to visually match input state
}

.snapshot_title___manual {
  @extend .snapshot_title;
  font-weight: bold;
}

.snapshot_name {
  color: wallaby.$d2-dark-gray;
}

.snapshot_meta {
  @include wallaby.overflow-hidden;
  color: wallaby.$d2-dark-gray;
  padding-left: 4px; // to align with title in its input form
}

.snapshot_input__wrapper {
  padding: 4px 8px;
}

.snapshot_input {
  height: 30px;
  line-height: 30px;
  min-height: unset;
}

// the dot dot dot button

// This positions the ... entire component on the right of the
// listing and centers the ... icon vertically
.snapshotmenu_button {
  align-items: center;
  color: wallaby.$theme-gray-8;
  display: flex;
  opacity: 0;
  padding: 0 6px;
  right: 0;

  // if open, show at full opacity
  &[data-expanded] {
    opacity: 1;
  }
}

.snapshot:hover .snapshotmenu_button {
  opacity: 0.4;

  // on hover, show at full opacity
  &:hover {
    opacity: 1;
  }
}
