
          @use '@scss/all' as wallaby;
        
.prompterView {
  inset: 0;
  position: fixed;
  z-index: 100;
}

.prompterView_contents {
  height: 100%;
}

.prompterView_controls {
  opacity: 0.3;
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;

  &:hover {
    opacity: 1;
  }
}

.prompterView_button {
  border-width: 0;

  &:hover {
    border-width: 1px;
  }
}

.prompterViewDark {
  background-color: wallaby.$theme-dark-9;
  color: wallaby.$theme-gray-0;

  .prompterView_button {
    color: wallaby.$theme-gray-0;
  }
}

.prompterView_scrollableText {
  height: 100%;
  padding: 0 100px;
}
