// FILTER
// as seen in comparison view
// can be further generalized down the road

.c-filter {
  background-color: $d1-purple;
  border-radius: 3px;
  color: $light-white;
  display: flex;
}

.c-filter__item {
  opacity: 0.7;
  padding: 4px 8px;

  &.c-filter__item--bolt {
    padding-right: 0;
  }

  &.c-filter__item--title {
    font-weight: bold;
    opacity: 1;
    padding: 4px 0;
  }

  &.c-filter__item--title:first-of-type {
    margin-left: 8px;
  }
}

.c-filter__divider {
  align-self: stretch;
  background-color: $light-white;
  display: flex;
  opacity: 0.25;
  width: 1px;
}

.c-filter__nav {
  font-size: $s1-font-size;
  margin: auto 8px auto 0;
}

.c-filter__button {
  background-color: transparent;
  border: 0;
  color: $light-white;

  &:hover {
    opacity: 1;
  }

  &.c-filter__button--nav {
    font-size: 14px;
    padding: 2px;
  }

  &.c-filter__button--selected {
    background-color: $d4-purple;
    opacity: 1;
  }
}
