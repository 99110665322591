
          @use '@scss/all' as wallaby;
        
.templateCard {
  border: 2px transparent solid;
  border-radius: 5px;
  padding: 5px;

  &:hover {
    background-color: wallaby.$theme-gray-1;
  }

  &:focus {
    border-color: wallaby.$theme-violet-1;
  }

  &.selected {
    background-color: #fff;
    border-color: wallaby.$theme-violet-6;
  }
}
