
          @use '@scss/all' as wallaby;
        
.collabPreferenceModal_description {
  align-items: center;
  display: flex;
  font-size: 16px;
  margin-bottom: 0 !important; // legacy `<p>` override
}

.collabPreferenceModal_body {
  align-items: center;
}
