
          @use '@scss/all' as wallaby;
        
.devSettings {
  padding: 0 30px;
}

.devSettings_card {
  min-width: 370px; // to match table below
}

.devSettings_secret {
  word-break: break-all;
}

.devSettings_codeGroup {
  align-items: center;
  background-color: wallaby.$theme-gray-0;
  border-radius: 3px;
  padding: 2px 10px;

  @media screen and (max-width: 820px) {
    flex-direction: column;
    gap: 0;
    justify-content: center;
    padding: 10px;
  }
}

.devSettings_actionGroup {
  @media screen and (max-width: 820px) {
    align-self: flex-end;
  }
}

.devSettings_actionIcon {
  &:hover {
    color: wallaby.$theme-dark-9;
  }
}

// we use this style for a lot of settings tables, maybe
// we should have a settings table row base component or change the theme
// to default this
.apiKeyTable {
  background-color: #fff;
  border: 1px solid wallaby.$theme-gray-3;
}

.apiKeyRow {
  background-color: #fff;
  border: 1px solid wallaby.$theme-gray-3;
}
